import React from "react";

import {
  Grid,
  Typography,
  Box,
  // Customizable Area Start
  Drawer
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import { penData, arrow, up_arrow, cart, truck, rupe, list, account } from "./assets";
import GroupData from "../src/GroupData.web";
import UserNavbar from "../../../components/src/UserNavbar.web";
import ProjectData from "../src/ProjectData.web";


const MainContent = styled("div")(({ theme }) => ({
    borderRight:"1px solid #E2E8F0",
    cursor:"default",
    height:"100%",
    "& .font-txt": {
      fontSize:"16px",
      fontWeight:400,
      fontFamily: 'Inter, sans-serif'
    },
    "& .type-txt": {
      fontSize:"14px",
      fontWeight:400,
      fontFamily: 'Inter, sans-serif',
      color:"#272727"
    },
    "& .type-txt-select": {
      fontSize:"14px",
      fontWeight:700,
      fontFamily: 'Inter, sans-serif',
      color:"#272727"
    }
  }))
// Customizable Area End

import UserCategoriesController, {
  Props,
} from "./UserCategoriesController";

export default class UserCategories extends UserCategoriesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
sidebarContent =() => {
  return(
  <MainContent>
  <Box style={webStyle.mainContent}>
      <Box style={webStyle.singleBox}>
        <Box><img src={penData} /></Box>
        <Box className="font-txt">Quotations</Box>
      </Box>
      <Box>
        <img src={arrow} alt="arrow"/>
      </Box>
  </Box>
  <Box style={webStyle.mainContent}>
    <Box style={webStyle.singleBox}>
      <Box><img src={cart} /></Box>
      <Box className="font-txt">Purchases</Box>
    </Box>
    <Box>
      <img src={arrow} alt="arrow"/>
    </Box>
  </Box>   
  <Box style={webStyle.mainContent}>
    <Box style={webStyle.singleBox}>
      <Box><img src={truck} /></Box>
      <Box className="font-txt">Delivery</Box>
    </Box>
    <Box>
      <img src={arrow} alt="arrow"/>
    </Box>
  </Box>  
  <Box style={webStyle.mainContent}>
    <Box style={webStyle.singleBox}>
      <Box><img src={rupe} /></Box>
      <Box className="font-txt">Payments</Box>
    </Box>
    <Box>
      <img src={arrow} alt="arrow"/>
    </Box>
  </Box>  
  <Box style={webStyle.mainContent}>
    <Box style={webStyle.singleBox}>
      <Box><img src={list} /></Box>
      <Box className="font-txt">Catalogue</Box>
    </Box>
    <Box>
      <img src={arrow} alt="arrow"/>
    </Box>
  </Box>   
  <Box data-test-id="group-btn" style={webStyle.toggleBox} onClick={this.handleToggle} >
    <Box style={webStyle.singleBox}>
      <Box><img src={account} /></Box>
      <Box className="font-txt" style={{ fontWeight:700}}>Group Name</Box>
    </Box>
    <Box>
      {this.state.openDialog ? (<img src={up_arrow} style={{ cursor:"pointer"}} alt="up_arrow"/>) : (<img src={arrow} style={{ cursor:"pointer"}} alt="arrow"/>)}
    </Box>
  </Box>    
  {this.state.openDialog && (
    <Box style={{ marginLeft: "67px", cursor:"pointer"}}>
      <Typography data-test-id="sidebar-btn" className={this.state.activeComponent === "Company" ? "type-txt-select" : "type-txt"} onClick={() => this.handleSidebarItemClick('Company')}>Company</Typography>
      <Typography data-test-id="sidebar-btn-project" className={this.state.activeComponent === "Project" ? "type-txt-select" : "type-txt"} onClick={() => this.handleSidebarItemClick('Project')} style={{ marginTop:"10px"}}>Project</Typography>
    </Box>
  )}
</MainContent> )

}
  // Customizable Area End

  render() { 
   
    return (
      // Customizable Area Start
        <Box>
          <UserNavbar handleToggleSidebar={this.handleToggleSidebar} />       
          <Grid container>
            <Grid item xl={3} lg={3}  spacing={3}>
            <CategoryMenu >
                <Drawer open={this.state.sidebarToggle} anchor="left" onClose={this.handleToggleSidebar} >
                 {this.sidebarContent()}
                </Drawer>
              </CategoryMenu>
              <CategoryBLock >
              {this.sidebarContent()}
              </CategoryBLock>              
            </Grid>
            <Grid item xl={9} lg={9} md={12} sm={12} xs={12} spacing={3}>
              {this.state.activeComponent === 'Company' && <GroupData/>}
              {this.state.activeComponent === 'Project' && <ProjectData/>}
            </Grid>
          </Grid>
        </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CategoryMenu = styled(Box)({
  display: "none",
  "@media (max-width: 600px)": {
    display: "block !important",
  },
  "@media only screen and (min-width: 600px) and (max-width: 900px) ": {
    display: "block !important",
  },
});
const CategoryBLock = styled(Box)({
  display: "block",

  height: "100%",
  marginRight: "5px",
  borderRight: "1px solid grey",
  "@media (max-width: 600px)": {
    display: "none !important",
  },
  "@media only screen and (min-width: 600px) and (max-width: 900px) ": {
    display: "none !important",
  },
});
const webStyle = {
  singleBox:{display: "flex", gap: "15px" }, 
  toggleBox:{display:"flex", alignItems:"center", justifyContent:"space-between", padding:"17px", cursor:"pointer",backgroundColor:"#f4f4f4", margin: "19px", border:"8px"},
  mainContent:{ display:"flex", alignItems:"center", justifyContent:"space-between", padding:"17px", margin: "19px"},
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End