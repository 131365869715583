//@ts-nocheck

import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Checkbox,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/VisibilityOutlined";
import ArrowDown from "@material-ui/icons/KeyboardArrowDownOutlined";
import Check from "@material-ui/icons/Check";
import ArrowRight from "@material-ui/icons/ArrowRight";
import BoltIcon from '@material-ui/icons/OfflineBoltOutlined';
import AddShopping from '@material-ui/icons/AddShoppingCartOutlined';
import INbox from '@material-ui/icons/InboxOutlined';
import History from '@material-ui/icons/HistoryOutlined';
import Planet from '@material-ui/icons/PublicOutlined';
import Truck from '@material-ui/icons/LocalShippingOutlined';
import ArrowForward from '@material-ui/icons/KeyboardArrowRight';
import Navbar from "../../../components/src/Navbar.web";
import Footer from "../../../components/src/Footer.web";
import {parcel} from "./assets"


const theme = createTheme({
  cursor:"default",
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const dummySpec = [
  {key: "Category", value: "Power Tools"},
  {key: "Sub - Category", value: "Professional Router"},
  {key: "Item Group", value: "Item Group"},
  {key: "Product Type", value: "Product Type"},
  {key: "HSN/SAC", value: "HSN/SAC"},
  {key: "Category", value: "Power Tools"},
  {key: "Sub - Category", value: "Professional Router"},
  {key: "Item Group", value: "Item Group"},
  {key: "Product Type", value: "Product Type"},
]
// Customizable Area End

import ProductDescriptionController, {
  Props,
  configJSON,
} from "./ProductDescriptionController";
import { dummyProduct } from "./assets";

export default class ProductDescription extends ProductDescriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.dummyImg = [dummyProduct, dummyProduct, dummyProduct, dummyProduct];
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getPostData();
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Navbar history={this.props.history} handleNavigate={() => this.handleNavigate()}/>
        <Grid container direction="row" alignItems="center" style={webStyle.navMenu}>
          <Grid item style={webStyle.navMenuItem} id="navigation">
            <span>Home</span>           
          </Grid>
          <ArrowForward />
          <Grid item style={webStyle.navMenuItem} id="navigation">
            <span>Power & Tools</span>           
          </Grid>  
          <ArrowForward />      
          <Grid item style={webStyle.navMenuItem} id="navigation">
            <span>Power Tools</span>           
          </Grid>
          <ArrowForward />
          <Grid item style={webStyle.navMenuItem} id="navigation">
            <span>Router</span>
          </Grid>
         
          
        </Grid>
        <Grid container style={webStyle.globalContainer}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Grid container className="products-photos" direction="column">
              <Grid item className="main-photo">
                
                <Grid container className="slider" style={{width:"25vw"}} direction="column" justifyContent="center">
                  <Grid item>
                    <img
                    src={this.dummyImg[this.state.focusedImageIndex]}
                    alt={`Product`}
                    style={webStyle.mainPhoto}
                    id="mainPhoto"
                    />
                  </Grid>
                  <Grid item style={{margin:"20px 0"}}>
                    <Grid container direction="row" style={{width:"25vw"}} justifyContent="space-between">
                    {this.dummyImg.map((item, index)=>{
                      return(
                        <img
                        src={item}
                        alt={`Product`}
                        id="carousel"
                        style={webStyle.notMainPhoto}
                        onClick={()=>{
                          this.setState({focusedImageIndex: index})
                        }}  
                      />
                      )
                    })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className="view-documents">
                <Typography style={webStyle.documentsText}>View Documents</Typography>
                <table style={webStyle.documentsTable}>
                  <tr>
                    <td style={webStyle.tableChildLeftUp}>Technical Data Sheets (TDS)</td>
                    <td style={webStyle.tableChildRightUp}>
                      <Visibility style={{ color: 'gray' }}/>
                    </td>
                  </tr>
                  <tr>
                    <td style={webStyle.tableChildLeftDown}>Brochure</td>
                    <td style={webStyle.tableChildRightDown}>
                      <Visibility style={{ color: 'gray' }}/>
                    </td>
                  </tr>
                </table>
              </Grid>
              
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5} style={webStyle.secondPart}>
              <Grid container className="NameVariations" direction="row">
                <Grid item className="NameFeature" direction="row">
                  <h2 style={webStyle.h2}>Bosch 1300W Professional Router, GOF 130</h2>
                  <h4 style={webStyle.h4}><span style={webStyle.h4Main}>Description:</span> uPVC fitting used to give a 90 turn to a pipeline.</h4>
                  <h4 style={webStyle.h4}><span style={webStyle.h4Main}>UPID:</span> XXXXXXXXXX</h4>
                  <h4 style={webStyle.h4}><span style={webStyle.h4Main}>Selected Brand:</span> Astral</h4>
                  <Grid container className="Feature" direction="column">
                    <h3 style={webStyle.h3}>Feature</h3>
                    <table style={webStyle.featureLabel}>
                      <tr>
                        <td>
                        </td>
                        <td>Category: Power Tools</td>
                      </tr>
                      <tr>
                        <td>
                          <Check color="#C6CAD5"></Check>
                        </td>
                        <td>Sub - Category:Professional Router</td>
                      </tr>
                      <tr>
                        <td>
                          <Check color="#C6CAD5"></Check>
                        </td>
                        <td>Item Group: Item Group</td>
                      </tr>
                      <tr>
                        <td>
                        </td>
                        <td>Product Type: Product Type</td>
                      </tr>
                      <tr>
                        <td>
                        </td>
                        <td>HSN/SAC: HSN/SAC</td>
                      </tr>
                    </table>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className="Variations" direction="column" style={{marginTop:"15px"}}>
                <span style={webStyle.h3}>Variations</span>
                <h1 style={webStyle.h4}>Lorem</h1>
                <Grid item>
                  <Grid container className="Buttons" direction="row">
                    <button style={webStyle.variationButton}>
                      <span style={webStyle.fontVariationButton}>
                        Variation 01
                      </span>
                    </button>
                    <button style={webStyle.variationButton}>
                      <span style={webStyle.fontVariationButton}>
                        Variation 02
                      </span>
                    </button>
                    <button style={webStyle.variationButton}>
                      <span style={webStyle.fontVariationButton}>
                        Variation 03
                      </span>
                    </button>
                    <button style={webStyle.variationButton}>
                      <span style={webStyle.fontVariationButton}>
                        Variation 04
                      </span>
                    </button>
                    <button style={webStyle.variationButtonActive}>
                      <span style={webStyle.fontVariationButton}>
                        Variation 05
                      </span>
                    </button>
                  </Grid> 
                </Grid>
              </Grid>
              <Grid item>
                  <Grid container className="Brands" direction="row">
                    <table style={webStyle.brandTable}>
                      <tr style={webStyle.brandTableRow}>
                        <th>Brand</th>
                        <th>Product Brand Name</th>
                        <th>Brand SKU</th>
                        <th></th>
                        <th></th>
                      </tr>
                      <tr>
                        <td style={webStyle.brandTableCell}>Finolex</td>
                        <td style={webStyle.brandTableCell}>Elbow 90 Plain</td>
                        <td style={webStyle.brandTableCell}>675427846</td>
                        <td style={webStyle.brandTableCell}>
                          <ArrowRight/>
                        </td>
                        <td style={webStyle.brandTableCell}>
                          <Checkbox
                            color="error"                            
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={webStyle.brandTableCell}>Paras</td>
                        <td style={webStyle.brandTableCell}>Elbow 90 Plain</td>
                        <td style={webStyle.brandTableCell}>675427846</td>
                        <td style={webStyle.brandTableCell}>
                          <ArrowRight/>
                        </td>
                        <td style={webStyle.brandTableCell}>
                          <Checkbox
                            color="error"                            
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={webStyle.brandTableCell}>Astral</td>
                        <td style={webStyle.brandTableCell}>Elbow 90 Plain</td>
                        <td style={webStyle.brandTableCell}>675427846</td>
                        <td style={webStyle.brandTableCell}>
                          <ArrowRight/>
                        </td>
                        <td style={webStyle.brandTableCell}>
                          <Checkbox
                            color="error"                            
                          />
                        </td>
                      </tr>
                    </table>                    
                  </Grid> 
              </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
              <Grid container direction="column">
                <Grid item>
                  <Grid container direction="column" className="quote" style={webStyle.quote} justifyContent="center">
                    <Grid item xs={10} sm={10} md={10} lg={10}>
                      <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <h4 style={webStyle.getQoute}>Get a Quote</h4>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                          <button style={webStyle.quoteButton}>
                            <BoltIcon />
                            Instant Quote
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={10} sm={10} md={10} lg={10}>
                      <Grid container direction="column" justifyContent="space-evenly" style={webStyle.quantity}>
                        <h5 style={webStyle.orderQuantityText}>Order Quantity<span style={{color:"#DC2626"}}>*</span></h5>
                        <Grid container direction="row" alignItems="center">
                          <input disabled style={webStyle.quantityInput} value={'Quantity'}></input>
                          <h7 style={webStyle.quantityNomer}>No.</h7>
                          <ArrowDown color="error" fontSize="small" />
                        </Grid>
                        <h4 style={webStyle.h4Convert}><span style={webStyle.h4ConvertMain}>Unit Conversion:</span> 1 bag = 20 kg</h4>
                        <Grid container direction="row" alignItems="center" justifyContent="flex-end">
                          <button style={webStyle.addQuotation}>
                            <AddShopping />
                            Add for Quotation
                          </button>
                        </Grid>
                      </Grid>
                      <Grid container direction="column" justifyContent="flex-start" style={webStyle.quantity}>
                        <Grid item >
                          <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={webStyle.quoteItem}>
                            <img src={parcel} style={{height:"20px", marginLeft:"3px"}} />
                            <div style={webStyle.quoteInfo}>
                              <span>MOQ</span>
                              <span style={webStyle.quoteInfoAdd}>20 No.s</span>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item >
                          <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={webStyle.quoteItem}>
                            <History />
                            <div style={webStyle.quoteInfo}>
                              <span>Lead Time</span>
                              <span style={webStyle.quoteInfoAdd}>Quote Required</span>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item >
                          <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={webStyle.quoteItem}>
                            <Planet />
                            <div style={webStyle.quoteInfo}>
                              <span>Regional Availabililty</span>
                              <span style={webStyle.quoteInfoAdd}>Available for your Project</span>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item >
                          <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={webStyle.quoteItem}>
                            <Truck />
                            <div style={webStyle.quoteInfo}>
                              <span>Transportation</span>
                              <span style={webStyle.quoteInfoAdd}>Quote Required</span>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container direction="column" style={webStyle.request}>
                <span style={webStyle.getQoute}>Request for a Sample</span>
                <button style={webStyle.requestButton}>
                  Request Sample
                </button>
                <span>Note: Sampling not available for this product.</span>
              </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container style={webStyle.description} direction="column">
                <Grid item>
                  <Grid container direction="row" alignItems="center">
                    <button id="button-1" style={this.state.pageDescription === 1 ? webStyle.descriptionButton__active : webStyle.descriptionButton} onClick={()=>{this.setState({pageDescription: 1})}}>Description</button>
                    <div style={webStyle.separator}></div>
                    <button id="button-2" style={this.state.pageDescription === 2 ? webStyle.descriptionButton__active : webStyle.descriptionButton} onClick={()=>{this.setState({pageDescription: 2})}}>Specifications</button>
                    <div style={webStyle.separator}></div>
                    <button id="button-3" style={this.state.pageDescription === 3 ? webStyle.descriptionButton__active : webStyle.descriptionButton} onClick={()=>{this.setState({pageDescription: 3})}}>Ways of Application</button>
                    <div style={webStyle.separator}></div>
                    <button id="button-4" style={this.state.pageDescription === 4 ? webStyle.descriptionButton__active : webStyle.descriptionButton} onClick={()=>{this.setState({pageDescription: 4})}}>Use</button>
                    <div style={webStyle.separator}></div>
                    <button id="button-5" style={this.state.pageDescription === 5 ? webStyle.descriptionButton__active : webStyle.descriptionButton} onClick={()=>{this.setState({pageDescription: 5})}}>Certifications</button>
                    <div style={webStyle.separator}></div>
                    <button id="button-6" style={this.state.pageDescription === 6 ? webStyle.descriptionButton__active : webStyle.descriptionButton} onClick={()=>{this.setState({pageDescription: 6})}}>Standards</button>
                    <div style={webStyle.separator}></div>
                    <button id="button-7" style={this.state.pageDescription === 7 ? webStyle.descriptionButton__active : webStyle.descriptionButton} onClick={()=>{this.setState({pageDescription: 7})}}>Quality Tests</button>
                    <div style={webStyle.separator}></div>
                    <button id="button-8" style={this.state.pageDescription === 8 ? webStyle.descriptionButton__active : webStyle.descriptionButton} onClick={()=>{this.setState({pageDescription: 8})}}>New Button</button>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column" style={webStyle.textDescription}>
                  {this.state.pageDescription === 1 ? <h7>The Bosch 1300W Professional Router is a powerful and versatile tool that can handle a variety of routing tasks. With its 1300 watt motor, it can easily handle even the most 
                    challenging routing jobs. </h7> : ""}
                  {this.state.pageDescription === 2 ? 
                  <Grid container direction="column" style={webStyle.spec}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <table style={webStyle.specTable}>
                        {dummySpec.map((item, index) => {
                          return (<tr>
                            <td style={webStyle.specKey}>{item.key}</td>
                            <td style={webStyle.specValue}>{item.value}</td>
                          </tr>)
                        })}
                      </table>
                    </Grid>
                  </Grid>
                  : ""}                  
                  </Grid>
                </Grid>
              </Grid>
          </Grid>
        </Grid>
        <Footer />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  globalContainer:{
    padding: "0px 90px",
    marginTop:"20px",
  },
  documentsTable: {
    borderRadius:"8px",
    border:"1px solid",
    fontFamily: 'Inter, sans-serif',
    fontSize: "16px",
    fontWeight: 700,
    borderColor:"#9095A0",
    width:"25vw"
  },
  tableChild:{
    padding:"19px",
    borderTop:"1px solid",
    borderLeft:"1px solid",
    borderColor:"#9095A0",
    textAlign:"center"
  },
  tableChildLeftUp: {
    padding:"19px",
    textAlign:"center",
  },
  tableChildRightUp: {
    padding:"19px",
    borderLeft:"1px solid",
    borderColor:"#9095A0",
    textAlign:"center"
  },
  tableChildLeftDown: {
    padding:"19px",
    borderTop:"1px solid",
    borderColor:"#9095A0",
    textAlign:"center"
  },
  tableChildRightDown: {
    padding:"19px",
    borderTop:"1px solid",
    borderLeft:"1px solid",
    borderColor:"#9095A0",
    textAlign:"center"
  },
  firstCell: {
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px"
  },
  lastCell: {
    border: "1px solid",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px"
  },
  variationButton: {
    width: "110px",
    padding: "10px 16px",
    margin: "14px",
    marginTop:"0px",
    border: '1px solid',
    borderColor: "#F0F0F0",
    borderRadius:"4px",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#EE6920",
      color: "#FFFFFF"
    },
    fontFamily: 'Inter, sans-serif',
  },
  fontVariationButton:{
    fontFamily: 'Inter, sans-serif',
    fontSize: "14px",
    fontWeight: 600,
  },
  variationButtonActive: {
    width: "110px",
    padding: "10px 16px",
    margin: "14px",
    marginTop:"0px",
    border: '1px solid',
    borderColor: "#F0F0F0",
    borderRadius:"4px",
    backgroundColor: "#EE6920",
    color: "#FFFFFF"
  },
  brandTable:{
    border:"1px solid",
    borderRadius:"10px",
    borderColor:"#9095A0"
  },
  brandTableCell:{
    borderTop:"1px solid",
    padding:"8px 24px",
    width: "calc(100% + 2%)",
    fontFamily: 'Inter, sans-serif',
    fontSize: "16px",
    fontWeight: 400,
    borderColor:"#9095A0"
  },
  brandTableRow:{
    backgroundColor: "#F3F4F6",
    fontFamily: 'Inter, sans-serif',
    fontSize: "16px",
    fontWeight: 600,
  },
  quoteButton:{
    width:"175px",
    height:"46px",
    color:"#FFFFFF",
    backgroundColor:"#E7A573",
    fontFamily: 'Inter, sans-serif',
    fontSize: "16px",
    fontWeight: 700,
    borderRadius:"8px",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-around",

  },
  quote:{
    border:"1px solid",
    borderRadius:"8px",
    marginLeft:"50px",
    borderColor:"#9095A0"
  },
  quantityInput:{
    width:"130px",
    height:"46px",
    backgroundColor:"#F3F4F6",
    borderRadius:"9px",
    alignItems:"center"
  },
  quantity:{
    marginLeft:"10%",
    marginBottom:"20px"
  },
  quantityNomer:{
    color:"red",
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 400,
    marginLeft:"30px"
  },
  addQuotation:{
    width:"190px",
    height:"46px",
    color:"#FFFFFF",
    backgroundColor:"#EE6900",
    fontFamily: 'Inter, sans-serif',
    fontSize: "16px",
    fontWeight: 700,
    borderRadius:"8px",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-around"
  },
  quoteInfo:{
    display: "flex",
    alignContent:"center",
    flexDirection:"column",
    marginLeft:"16px",
    fontFamily: 'Inter, sans-serif',
    fontSize: "16px",
    fontWeight: 500,
  },
  quoteItem:{
    marginTop:"16px"
  },
  request:{
    border:"1px solid",
    borderRadius:"8px",
    borderColor:"#9095A0",
    marginLeft:"50px",
    marginTop:"20px",
    padding:"15px",
    fontFamily: 'Inter, sans-serif',
    fontSize: "14px",
    fontWeight: 400,
  },
  requestButton:{
    color:"#FFFFFF",
    backgroundColor:"#E7A573",
    width:"130px",
    height:"46px",
    borderRadius:"9px",
    margin:"15px 0",
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 400,
  },
  description:{
    border: "1px solid",
    borderRadius:"9px",
    marginTop:"60px",
    padding:"24px",
    borderColor:"#9095A0",
    marginBottom:"60px",
  },
  descriptionButton__active:{
    width:"180px",
    height:"44px",
    borderRadius:"9px",
    backgroundColor:"#FF6600",
    color:"#FFF",
    padding:"0 46px",
    fontFamily: 'Inter, sans-serif',
    fontSize: "16px",
    fontWeight: 500,
  },
  descriptionButton:{
    width:"180px",
    height:"44px",
    backgroundColor:"#FFF",
    color:"#FF6600",
    padding:"0 46px",
    fontFamily: 'Inter, sans-serif',
    fontSize: "16px",
    fontWeight: 500,
    border:"1px solid",
    borderColor:"#FFFFFF"
  },
  separator:{
    height:"22px",
    border:"1px solid",
    borderColor:"#FFEBDD",
  },
  textDescription:{
    marginTop:"25px",
    fontFamily: 'Inter, sans-serif',
    fontSize: "20px",
    fontWeight: 400,
    color:"#334155",
  },
  navMenu:{
    marginLeft:"90px",
    marginTop:"22px",
    fontFamily: 'Inter, sans-serif',
    fontSize: "16px",
    fontWeight: 700,
    color:"#1E293B",
  },
  navMenuItem:{
    height:"19px",
    alignItems:"center",
  },
  notMainPhoto:{
    width: "20%",
    borderRadius:"12px"
  },
  mainPhoto:{
    width:"25vw", 
    height:"50vh",
    borderRadius:"12px",
  },
  documentsText:{
    fontFamily: 'Inter, sans-serif',
    fontSize: "16px",
    fontWeight: 700,
  },
  secondPart:{
    fontFamily: 'Inter, sans-serif',
  },
  h2:{
    fontSize: "34px",
    fontWeight: 600,
  },
  h4:{
    fontSize: "16px",
    fontWeight: 500,
  },
  h4Main:{
    fontWeight: 600,
  },
  h3:{
    fontSize: "18px",
    fontWeight: 600,
  },
  featureLabel:{
    fontSize: "16px",
    fontWeight: 500,
    color:"#9095A0"
  },
  getQoute:{
    fontSize: "20px",
    fontWeight: 600,
    color:"#272727",
    fontFamily: 'Inter, sans-serif',
  },
  orderQuantityText:{
    fontFamily: 'Inter, sans-serif',
    fontSize: "14px",
    fontWeight: 700,
  },
  h4Convert:{
    fontFamily: 'Inter, sans-serif',
    fontSize: "16px",
    fontWeight: 500,
    color:"#666666"
  },
  h4ConvertMain:{
    color:"#1E293B"
  },
  quoteInfoAdd:{
    color:"#666666",
  },
  specTable:{
    width:"100%",
    fontFamily: 'Inter, sans-serif',
    fontSize: "16px",
    fontWeight: 500,
  },
  spec:{
    height:"580px"
  },
  specKey:{
    height:"50px",
    backgroundColor:"#F3F4F6",
    color:"#1E293B",
    padding:"20px",
    width:"270px"

  },
  specValue:{
    height:"50px",
    width:"60%",
    color:"#666666",
     padding:"20px",
  }
};
// Customizable Area End
