import React from "react";

import {
  Box,
  Typography,
  Hidden,
  Button,
  Dialog,
  DialogContent,
  BoxProps
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import { navData, addData, vector } from "./assets";
import BillingAddBuyer from "../src/BillingAddBuyer.web";
import DeliveryAddBuyer from "../src/DeliveryAddBuyer.web";
import ReraBuyer from "../src/ReraBuyer.web";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CloseIcon from '@material-ui/icons/Close';

const ProjectBlock = styled("div")(({ theme }) => ({
    padding:"24px",
    "& .nav-box": {
      padding: "10px",
      borderRadius:"8px",
      border:" 1px solid #E2E8F0",
    },
    "& .heading-txt": {
        fontSize:"20px",
        fontWeight:700,
        fontFamily: 'Inter, sans-serif',
        color:"#0A0528"
    },
    "& .upload-btn":{
        width: "212px",
        height: "44px",
        margin: "22px 28px",
        borderRadius:"8px",
        fontWeight:700,
        fontFamily: 'Inter, sans-serif',
        border:"1px solid #FF6600",
        color:"#FF6600",
        fontSize:"14px",
        textTransform: "capitalize"
    },
    "& .sort-data": {
        display: "flex",
        borderRadius:"4px",
        alignItems: "center",
        width: "128px",
        height: "44px",
        border: "1px solid #CBD5E1", 
        justifyContent: "center",
        position:"relative"
      },
     "& .drop-data": {
        display: "flex",
        gap: "7px",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer"
      },
      "& .text-input-data":{
        width:"96px",
        fontSize:"14px",
        color:"#979797"
      },
      "& .dropdown-list-data-language li": {
        padding: "12px 19px",
      },
      "& .dropdown-list-data-language": {
        top: "35px",
        backgroundColor:"#FFFFFF",
        borderRadius: "8px",
        fontFamily: "Red Hat Display",
        position: "absolute",
        // maxHeight: "400px",
        border: "1px solid #CBD5E1",
        fontWeight:400,
        zIndex: 100,
        padding: "5px",
        listStyle: "none",
        transition: "all 0.3s ease-out",
        fontSize: "14px",
        cursor: "pointer",
        width: "120px",
        maxHeight: "200px",
        overflowY: "auto"
      },
      "& .edit-btn":{
        width: "90px",
        height: "44px",
        margin: "22px 28px",
        borderRadius:"8px",
        border:"1px solid #64748B",
        color:"#64748B",
        fontSize:"14px",
        fontWeight:700,
        fontFamily: 'Inter, sans-serif',
        textTransform: "capitalize"
      },
      "& .dropdown-list-data-language li:last-of-type a": {
        border: "none",
      },
      "& .save-btn":{
        width: "90px",
        height: "44px",
        margin: "22px 28px",
        borderRadius:"8px",
        border:"1px solid #FF6600",
        color:"white",
        fontSize:"14px",
        fontWeight:700,
        fontFamily: 'Inter, sans-serif',
        backgroundColor:"#FF6600",
        textTransform: "capitalize"
      },
      "& .details-data": {
        border: "1px solid #E2E8F0",
        borderRadius: "8px",
        paddingBottom: "30px",
      },
     "& .text-data": {
        fontSize: "16px",
        fontWeight:500,
        fontFamily: 'Inter, sans-serif',
        color:"#272727"
      },
      "& .MuiTabs-scroller.MuiTabs-fixed .MuiTabs-indicator" :{
        borderBottom: "1px solid #FF6600 !important",
        backgroundColor: "#FF6600"
      },
      "& .MuiTabs-centered": {
        justifyContent:"left",
        borderBottom: "1px solid #CBD5E1",
      },
      "& .MuiTab-textColorPrimary.Mui-selected": {
        color:"#FF6600"
      },
      "& .custom-tab-label": {
        textTransform: "lowercase" 
      },
     "& .custom-tab-label::first-letter": {
        textTransform: "uppercase"
      },
      "& .dropdown-item": {
        display: "flex",
        alignItems: "center"
      },
      "& .dropdown-item:hover": {
        backgroundColor: "#FFE0CC",
        borderRadius:"8px"
      },
  }))

const DialougBlock = styled(Dialog)(({ theme }) => ({
  "& .dialoug-data": {
    display:"flex", 
    alignItems:"center", 
    justifyContent:"space-between", 
    width:"508px"
  },
  "& .dialoug-main": {
    display: "flex",
    borderRadius:"4px",
    alignItems: "center",
    width: "520px",
    height: "44px",
    border: "1px solid #CBD5E1", 
    position:"relative",
    marginTop:"7px"
  },
  "& .dropdown-list-dialoug li": {
    padding: "12px 19px",
  },
  "& .dropdown-list-dialoug": {
    top: "35px",
    backgroundColor:"#FFFFFF",
    borderRadius: "8px",
    fontFamily: "Red Hat Display",
    position: "absolute",
    maxHeight: "400px",
    border: "1px solid #CBD5E1",
    fontWeight:400,
    zIndex: 100,
    padding: "5px",
    listStyle: "none",
    transition: "all 0.3s ease-out",
    fontSize: "14px",
    cursor: "pointer",
    width: "508px",
  },
  "& .dropdown-item": {
    display: "flex",
    alignItems: "center"
  },
  "& .dropdown-item:hover": {
    backgroundColor: "#FFE0CC",
    borderRadius:"8px"
  },
}))

// Customizable Area End

import ProjectDataController, {
  Props,
} from "./ProjectDataController";

export default class ProjectData extends ProjectDataController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  companyName = [
    { label: "Company1", key:0 },
    { label: "Company2", key:1 },
    { label: "Company3", key:2 }
  ]; 

  renderPopup = () => {
    const BoxView = Box as React.FC<BoxProps>;
        return(
          <>
          <DialougBlock
            open={this.state.openDialog}
            onClose={this.handleClose}
            style={{ borderRadius: "50px" }}
            PaperProps={{
              style: {
                borderRadius: "8px",width:"600px",height:"400px",
              },
            }}
          >
             <BoxView
              data-test-id="close-btn"
              display={"flex"}
              style={{ justifyContent: "space-between",
              margin: "5%",
              marginBottom: "0px",
              cursor: "pointer" }}
              onClick={this.handleClose}
              >
                <Typography style={{ fontSize: "24px", fontFamily: 'Inter, sans-serif', fontWeight:700, color:"#272727"}}>Add New Project</Typography>
                <CloseIcon />
              </BoxView>
              <DialogContent
                style={{ fontFamily: "Panton Narrow Bold",lineHeight: "26px", fontSize:"24px",color: "#000000",
                fontWeight: 700, overflowX: "hidden",}}
              >
                 <div style={{ border:"1px solid #E2E8F0", width:"100%"}}></div>
                <Box style={{ marginTop:"35px"}}>
                  <Typography style={{ fontSize: "14px",fontWeight: 700,color:"#334155"}}>Company Name</Typography>
                  <Box className="dialoug-main" data-test-id="company-drop-btn" onClick={this.handleFormOpen}>
                    <Box className="dialoug-data" data-test-id="form-open">
                      <Typography style={{ marginLeft:"9px", fontSize:"14px"}}>{this.state.selectedCompany.companyName}</Typography>
                      <img src={vector} alt="vector"/>
                    </Box>
                    <Hidden only="xs">
                    {this.state.showFormData && (
                    <ul className="dropdown-list-dialoug">
                       {this.state.companyNamesList.map((item:{id:number,companyName:string}) => (
                        <li
                          id="dropdowndata"
                          className="dropdown-item"
                          key={item.companyName}
                          onClick={() => this.selectComName(item)}
                          data-test-id="dialoug-com-drop"
                        >
                          <div style={{ fontFamily: 'Inter, sans-serif'}}>{item.companyName}</div>
                        </li>
                        ))}
                    </ul>
                    )}
                    </Hidden>
                  </Box>
                </Box>
                <Box style={{ marginTop:"25px"}}>
                  <Typography style={{ fontSize: "14px", color:"#334155",fontWeight: 700}}>Project Name</Typography>
                  <input id="company" data-test-id="project-input" style={{ width: "520px", height: "44px",borderRadius: "8px", border: "1px solid #CBD5E1",padding: "10px" }} type="text" name="projectname" onChange={this.handleProjectChange}></input>
                </Box>
                <div style={{ border:"1px solid #E2E8F0", marginTop:"37px", width:"100%"}}></div>
                <Box style={{ display: "flex", marginTop: "15px",justifyContent: "end", gap: "20px"}}>
                  <Button style={{
                    height: "44px",
                    borderRadius:"8px",
                    width: "120px",
                    fontWeight:700,
                    fontFamily: 'Inter, sans-serif',
                    border:"1px solid #64748B",
                    color:"#64748B",
                    fontSize:"14px",
                    textTransform: "capitalize"
                  }}
                    onClick={this.handleClose}
                  >Cancel
                  </Button>
                  <Button style={{
                    fontWeight:700,
                    fontFamily: 'Inter, sans-serif',
                    backgroundColor:"#FF6600",
                    width: "120px",
                    height: "44px",
                    color:"white",
                    fontSize:"14px",
                    borderRadius:"8px",
                    border:"1px solid #FF6600",
                    textTransform: "capitalize"
                  }}
                  data-test-id="add-company-btn"
                  onClick={this.hadleAddProject}
                  >Add</Button>
                </Box>
              </DialogContent>
          </DialougBlock>
          </>
        )
  }
  // Customizable Area End

  render() { 

    return (
      // Customizable Area Start
      <ProjectBlock>
        <Box style={{ display:"flex", justifyContent:"space-between"}}>
          <Box style={{ display:"flex", alignItems: "center", justifyContent: "center", gap: "13px"}}>
              <Box className="nav-box">
                  <img src={navData} alt="navData"/>
              </Box>
              <Typography className="heading-txt">Mihir Group</Typography>
          </Box>
          <Box>
            <Button data-test-id="dialoug-btn" onClick={this.handleComOpen} className='upload-btn'><img style={{ marginLeft:"4px", marginRight:"7px"}} src={addData} alt="addData" />Add new Project</Button>
            {this.renderPopup()}
          </Box>
        </Box>

        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop:"-18px"}}>
            <Box style={{ display: "flex", gap:"20px"}}>
                <Box>
                  <Box className="sort-data">
                    <Box className="drop-data" data-test-id="company-sort-btn" onClick={this.handleOpen}>
                    <Typography className="text-input-data">
                      {this.state.selectedDropCompany.companyName.length > 10 ? (
                        <>
                          {this.state.selectedDropCompany.companyName.slice(0, 15)}...
                        </>
                      ) : (
                        this.state.selectedDropCompany.companyName || 
                        (this.state.companyNamesList.length > 0 && this.state.companyNamesList[0].companyName)
                      )}
                    </Typography>
                    <img src={vector} alt="vector"/>
                    </Box>
                    <Hidden only="xs">
                    {this.state.showComData && (
                      <ul className="dropdown-list-data-language">
                        {this.state.companyNamesList ? (
                          <>
                          {this.state.companyNamesList.map((item:{id:number,companyName:string}) => (
                          <li
                            id="dropdowndata"
                            className="dropdown-item"
                            key={item.companyName}
                            onClick={() => this.selectComNameDrop(item)}
                            data-test-id="company-data"
                          >
                            <div style={{ fontFamily: 'Inter, sans-serif'}}>{item.companyName}</div>
                          </li>
                          ))}
                          </>
                        ) : (<Typography>No data found</Typography>)}
                      </ul>
                    )}
                    </Hidden>
                  </Box>
                </Box>

                <Box>
                  <Box className="sort-data">
                    <Box className="drop-data" data-test-id="sort-btn-project" onClick={this.handleProjectOpen}>
                    <Typography className="text-input-data">
                      {this.state.selectProjectName.projectName.length > 10 ? (
                        <>
                          {this.state.selectProjectName.projectName.slice(0, 15)}...
                        </>
                      ) : (
                        this.state.selectProjectName.projectName || 
                        (this.state.projectNameList.length > 0 && this.state.projectNameList[0].projectName)
                      )}
                    </Typography>
                    <img src={vector} alt="vector"/>
                    </Box>
                    <Hidden only="xs">
                    {this.state.showProjectData && (
                      <ul className="dropdown-list-data-language">
                        {this.state.projectNameList ? (
                          <>
                          {this.state.projectNameList.map((item:{id:number,projectName:string}) => (
                            <li
                              key={item.projectName}
                              id="dropdowndata"
                              className="dropdown-item"
                              data-test-id="project-data"
                              onClick={()=> this.selectProjectName(item)}
                            >
                              <div style={{ fontFamily: 'Inter, sans-serif'}}>{item.projectName}</div>
                            </li>
                        ))}
                          </>
                        ) : (<Typography>No data found</Typography>)}
                      </ul>
                    )}
                    </Hidden>
                  </Box>
                </Box>
            </Box>
            <Box>
            <Box>
            {this.state.isEdit ? (
              <Button data-test-id="save-btn" className="save-btn" onClick={this.handleSaveProject}>
                Save
              </Button>
            ) : (
              <Button className="edit-btn" onClick={this.handleEdit}>
              Edit
              </Button> 
            )}
          </Box>
            </Box>
        </Box>

        <Box className="details-data">
          <Tabs
            data-test-id="tab-btn"
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
            >
            <Tab label={<span className="custom-tab-label">Billing Address</span>}/>
            <Tab label={<span className="custom-tab-label">Delivery Address</span>}/>
            <Tab label="RERA"/>
          </Tabs>
          {this.state.value === 0 && <BillingAddBuyer billAdd={this.state.billAdd} passState={this.handleBillingBuyerState} isEdit={this.state.isEdit} fieldRequiredData={this.state.fieldRequiredData}/>}
          {this.state.value === 1 && <DeliveryAddBuyer deliveryAdd={this.state.deliveryAdd} passState={this.handleDeliveryBuyerState} isEdit={this.state.isEdit} fieldRequiredData={this.state.fieldRequiredData}/>}
          {this.state.value === 2 && <ReraBuyer reraData={this.state.reraData} passState={this.handleReraBuyerState} isEdit={this.state.isEdit}/>}
        </Box>
      </ProjectBlock>
    
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  };
// Customizable Area End