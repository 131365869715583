import React, { Component, ComponentType } from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Button, Dialog, Checkbox, IconButton } from '@material-ui/core';
import { styled } from "@material-ui/styles";
import { Message } from "../../../packages/framework/src/Message";
import { BlockComponent } from "../../../packages/framework/src/BlockComponent";
import { runEngine } from "../../../packages/framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../packages/framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
import { Menu } from '@material-ui/icons';

import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
type S = { showDropDownCountry: boolean, showDropDownLanguage: boolean, scrollPosition: number, showDropDownCategori: boolean, selectedCategory:string, isSubMenuOpen: boolean, token: any, catData: any, catAllData: any, subCat:any, selectedItem: any, dropAccount: boolean, showCartData:boolean };
interface Props {
  handleNavigate ?: ()=>void;
  handleToggleSidebar: ()=>void;
//   history: any
}


const NavigationBlock = styled("div")(({ theme }) => ({
      height:"76px",
      fontFamily: 'Inter, sans-serif',
      "& .MuiTypography-body1":{
        fontFamily: 'Inter, sans-serif',
      },
    '& .logo': {
        width:"92px",
        height:"48px",
        margin: "auto 0",
        '@media (max-width: 600px)': {
          width:"50px",
        height:"30px",
        marginLeft:"-20px",
        marginRight:"0px",
        }
    },
    '& .dropdown-list': {
        display: "flex",
        alignItems:"center",
        justifyContent:"center",
        marginRight:"10px"
    },
    '& .location-icon': {
        width:"20px",
        height: "22px"
    },
        '& .down-icon': {
        cursor: "pointer",
        margin:"1px"
    },
    "& .dropdown-list-data": {
        fontFamily: "Red Hat Display",
        position: "absolute",
        maxHeight: "400px",
        top: "45px",
        backgroundColor:"#FFFFFF",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        listStyle: "none",
        transition: "all 0.3s ease-out",
        fontSize: "16px",
        zIndex: 100,
        padding: "5px",
        cursor: "pointer",
        left: "156px",
        width: "162px",
        '@media (max-width: 600px)': {
          left: "70px !important",  
        },
        '@media (max-width: 900px)': {
          left: "120px", 
        }
      },
      "& .dropdown-list-data li": {
        padding: "0px 20px",
        margin: "15px 0px",
      },
      "& .dropdown-list-data li:last-of-type a": {
        border: "none",
      },
      "& .dropdown-list a": {
        margin: "4px",
        display: "inline-block",
        textDecoration: "none",
        fontSize: "16px",
        fontWeight: "400",
        color: "#FAFAFA",
        padding: " 10px 0",
        transition: "all 0.3s ease-out",
        zIndex: 100,
      },
      "& .dropdwon-box": {
        position: "relative",
        "& .dropdown-wrapper": {
          position: "absolute"
        }
      },
      "& .dropdown-item": {
        display: "flex",
        alignItems: "center"
      },
      "& .dropdown-item .selected": {
        backgroundColor: "#FFE0CC", 
        color: "black",
        display: "flex",
        alignItems: "center"
      },
      "& .dropdown-item:hover": {
        backgroundColor: "#FFE0CC",
        borderRadius:"8px"
      },
      "& .search-icon": {
        width: "24px",
        height: "24px"
      },   
      "& .upload-btn":{        
        width: "142px",
        height: "44px",
        margin: "22px 28px",
        borderRadius:"8px",
        border:"1px solid #64748B",
        color:"#64748B",
        fontSize:"14px",
        fontWeight:700,
        fontFamily: 'Inter, sans-serif',
        '@media (max-width: 600px)': {
          width:"10px",
          height:"32px",
          margin:"auto 10px",
          marginTop:"9px"
        }
      },
      "& .user-data":{
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        marginRight:"47px",
        gap:"10px",
        marginBottom:"11px"
      },
      "& .dropdown-list-account": {
        fontFamily: "Red Hat Display",
        position: "absolute",
        maxHeight: "400px",
        top: "60px",
        right:"16px",
        backgroundColor:"#FFFFFF",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        listStyle: "none",
        transition: "all 0.3s ease-out",
        fontSize: "16px",
        zIndex: 100,
        padding: "5px",
        cursor: "pointer",
        width: "162px",
      },
      "& .dropdown-list-account li": {
        padding: "0px 20px",
        margin: "15px 0px",
        gap:"16px"
      },
      "& .dropdown-list-account li:last-of-type a": {
        border: "none",
      },
      "& .cart-main":{
        fontFamily: "Red Hat Display",
        position: "absolute",
        maxHeight: "400px",
        top: "69px",
        backgroundColor:"#FFFFFF",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        listStyle: "none",
        transition: "all 0.3s ease-out",
        fontSize: "16px",
        zIndex: 100,
        padding: "5px",
        cursor: "pointer",
        width: "497px",
        height:"361px",
        right:"10px"
      },
      "& .close-btn":{
        display: "flex",
        flexDirection: "row-reverse",
        marginTop: "31px",
        marginRight: "34px",
      },
      "& .view-btn":{
        border: "1px solid #FF6600",
        width: "405px",
        height: "50px",
        borderRadius: "10px",
        marginLeft:"10px",
        marginTop:"10px"
      },
      "& .input-step":{
        display: "inline-flex",
        width:"90px",
        // height: "37.5px",
        overflow: "visible",
        padding: "4px",
        border:"1px solid #94A3B8",
        height:"30px",
        borderRadius:"8px"
      },
      "& .input-step input":{
        background: "#0000",
        border: "0",
        color: "black",
        height: "100%",
        textAlign: "center",
        width: "4em"
      }
  }
))
interface SS {
  id: any;
}
class UserNavbar extends BlockComponent<Props, S, SS>{
  getCatList: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        showDropDownCountry: false,
        showDropDownLanguage: false,
        scrollPosition: 0,
        showDropDownCategori: false,
        selectedCategory: "",
        isSubMenuOpen: false,
        token:"",
        catData: [],
        catAllData: [],
        subCat: "",
        selectedItem:"",
        dropAccount: false,
        showCartData: false,      
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    await super.componentDidMount();
    const token = (typeof localStorage !== "undefined" && localStorage.getItem('otp-token')) || '';
    this.setState({ token: token})
    this.getCategory()
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: any): void {
    
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId == this.getCatList){
      const categoryData = responseJson?.data?.map((category:any) => category.attributes.name)
      const cat = responseJson?.data?.map((category:any) => category.attributes)
      this.setState({ catData: categoryData, catAllData: cat})
    }
    // Customizable Area Start
    // Customizable Area End
  }

  handleDropdownClick = (label: any) => {
    const subCategories = label.sub_categories.map((subCategory:any) => subCategory.name);

    this.setState((prevState:any) => ({
      showDropDownCategori: true,
      isSubMenuOpen: !prevState.isSubMenuOpen, 
      subCat: subCategories,
      selectedItem: label.name
    }));
  };

  handleAccountClick = (label: any) => {
    if(label === "Logout"){
      localStorage.removeItem('otp-token');
      localStorage.clear();
    }
  };

  handleToggleSubMenu = () => {
    this.setState((prevState: any) => ({
      isSubMenuOpen: !prevState.isSubMenuOpen,
    }));
  };

  handleDropdownOpen = () => {
    this.setState({ showDropDownCountry: !this.state.showDropDownCountry })
  }

  handleDropdownLanguageOpen = () => {
    this.setState({ showDropDownLanguage: !this.state.showDropDownLanguage })
  }

  handleAccountOpen = () => {
    this.setState({ dropAccount: !this.state.dropAccount})
  }

  handleDrodownCat = () => {
    this.setState({ showDropDownCategori: !this.state.showDropDownCategori})
  }

  handleScroll = (scrollType: any) => {
    const container = document.getElementById('scroll-container');
    const scrollAmount = 200; 
    const newPosition =
      scrollType === 'right'
        ? this.state.scrollPosition + scrollAmount
        : this.state.scrollPosition - scrollAmount;

        if (container) {
            container.scrollTo({
              top: 0,
              left: newPosition,
              behavior: 'smooth',
            });
          }

    this.setState({ scrollPosition: newPosition})
  };
  cartOpen = () => {
    this.setState({ showCartData: !this.state.showCartData })
  }  
  getCategory = async () => {
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCatList = getValidationsMsg.messageId
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_categories/categories/category_list")
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
 

  render() {
    const menuItems = [
        { label: "India" },
        { label: "USA" },
        { label: "Canada"},
      ];

    const languageItems = [
        { label: "English" },
        { label: "Spanish" },
      ]; 
         
    const accountItems = [
        { label: "My Profile", image: require("./profile.jpg") },
        { label: "Dashboard", image: require("./dashboard.jpg")},
        { label: "Settings", image: require("./setting.jpg") },
        { label: "Logout", image: require("./logout.jpg") },
      ];   
    return (
        <>
          <NavigationBlock>
            <Box sx={webStyle.navbar}>   
              <Box style={webStyle.navBox}>  
              <MasterFinances >
            <IconButton onClick={this.props.handleToggleSidebar}>
              <Menu />
            </IconButton>
          </MasterFinances>    
                  <img className="logo" src={require("./kx_logo.jpg")} alt="kxLogo" />
                  <Box sx={webStyle.searchContent}>
                      <Typography variant='body1'  style={{color:"#94A3B8", fontFamily: 'Inter, sans-serif'}}>Search Product</Typography>
                      <img className='search-icon' src={require("./search.jpg")} alt="search" />
                  </Box>
              </Box>  

                        
              <Box style={webStyle.navItems}>
                <Box>
                    <Button className='upload-btn' style={{textTransform:"none"}}>
                      <Box sx={webStyle.displayUpload}> Upload RFQ </Box>
                      <img style={{ marginLeft:"4px"}} src={require("./uploaduser.jpg")} alt="upload" /></Button>
                </Box>
                <Box  sx={webStyle.languageContainer}>
                    <img src={require("./order.jpg")} alt="order" />
                    <Box sx={webStyle.displayOrderBox}>
                    <Typography style={{fontSize: "16px",fontWeight: 700, fontFamily: 'Inter, sans-serif', color:"#64748B"}}>Orders</Typography>
                    </Box>
                </Box>
                <Box  sx={webStyle.languageContainer} onClick={()=>this.props.handleNavigate!()}>
                    <Box>
                      <img src={require("./mihir.jpg")} alt="mihir"/>
                    </Box>
                    <Box sx={webStyle.displayBox}>
                      <Typography style={webStyle.logoText} >Mihir Group</Typography>
                    </Box>
                </Box>
              </Box>
            </Box>
          </NavigationBlock>
        </>
    )
  }
}
const MasterFinances = styled(Box)({
  display: "none !important",
  "@media (max-width: 600px)": {
    display: "block !important",
  },
  "@media only screen and (min-width: 600px) and (max-width: 900px) ": {
    display: "block !important",
  },

});
const webStyle = {
  logoText: {
    marginLeft: "10px",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: 'Inter, sans-serif',
    cursor: "pointer"
  },
  navBox:{ display:"flex", gap:"20px",
  "@media (max-width: 600px)": {
    gap:"10px",
    width:"50vw",
  },

},
  
  profileIcon : {
    color:"#FFFFFF",
    fontSize:"30px",
  },
  profileIconContainer:{
    height:"44px",
    width:'44px',
    borderRadius:"50%",
    bgcolor: "#FF6600",
    display:'flex',
    justifyContent:"center",
    alignItems:"center",
    '@media (max-width: 600px)': {
      width:"30px",
      height:"30px",  
      padding:"3px"   
    }
},
bulkOrder:{
  fontWeight: 700, fontFamily: 'Inter, sans-serif',fontSize:{xs:"12px" ,sm:"16px"}, minWidth:"max-content" 
},
  navbar: {
    display:'flex',
    justifyContent:"space-between",
    alignItems:"center",
    padding:"5px",
    height:"76px",
    borderBottom:"1px solid #E2E8F0",
    "@media (max-width:900px)": {
      justifyContent:"space-around",
      width:"90vw"
    },
  },
  languageContainer: {
    display: "flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"row",
    gap:"10px",
    marginRight:"17px",
    "@media (max-width:900px)": {
      width:"13px",
      marginTop:"8px",
      justifyContent:"space-between",
    },
  },
  displayBox:{
    display:{xs:"none" ,md:'block',lg:"block"}
  },
  displayUpload:{
    display:{xs:"none" ,sm:"block",md:'block',lg:"block"}
  },
  displayOrderBox:{
    display:{xs:"none" ,sm:"none",md:'block',lg:"block"}
  },
  searchContent: {
    margin:"0 10px",
    display: "flex",
    flexDirection: "row",
    border: "1px solid #CBD5E1",
    padding: "0px 12px",
    width: "300px",
    height:"40px",
    justifyContent: "space-between",
    borderRadius: "10px",
    alignItems:"center",
    fontSize:"50px",
    '@media (max-width: 600px) ': {
      width: "78px !important",
      '& .MuiTypography-body1':{
        fontSize:"10px !important"
      }   
    },
    '@media (max-width: 688px) and (min-width: 600px)': {
      width: "110px !important",       
    },
    '@media (max-width: 900px)': {
      width: "180px",
      height:"30px",
      marginTop:"11px",
      marginLeft:"-5px",
      marginRight:"5px",
      '& .MuiTypography-body1':{
        fontSize:"10px !important"
      }   
    },
   },
  product:{
    '@media (max-width: 600px)': {
      display: "none",
    }
  },

  navItems: {
    display: "flex",
    '@media (max-width: 600px)': {
      alignItems: "center",
      minWidth: "26%",
      padding:'0 55px',
      width:"50vw",
    }   
  }
};
export default UserNavbar