import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  
  // Customizable Area End
}

interface S {
//   txtInputValue: string;
//   txtSavedValue: string;
//   enableField: boolean;
    // Customizable Area Start
    sidebarToggle:boolean;
    openDialog: boolean;
    activeComponent: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class UserCategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllCompanyList: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
    //   txtInputValue: "",
    //   txtSavedValue: "A",
    //   enableField: false,
      
            // Customizable Area Start
            sidebarToggle:false,
            openDialog:true,
            activeComponent: "Company"
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start  
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount(); 

  }

  // Customizable Area Start

  handleToggle = () => {
    this.setState({ openDialog: !this.state.openDialog});
  };

  handleSidebarItemClick = (componentName: string) => {
    this.setState({ activeComponent: componentName });
  }
  handleToggleSidebar = () => {     
    this.setState({ sidebarToggle: !this.state.sidebarToggle});}
   
  // Customizable Area End
}
