import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Button, Checkbox, Grid, Drawer } from '@material-ui/core';
import Hidden from "@material-ui/core/Hidden";
import { styled } from "@material-ui/styles";
import { Message } from "../../../packages/framework/src/Message";
import { BlockComponent } from "../../../packages/framework/src/BlockComponent";
import { runEngine } from "../../../packages/framework/src/RunEngine";
import { ExpandMore, ChevronLeft, ChevronRight } from '@material-ui/icons';
import MessageEnum, {
  getName
} from "../../../packages/framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
import Select, { components } from 'react-select';

import { ComponentProps } from 'react';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIco from '@material-ui/icons/CloseOutlined'
import ArrowDown from "@material-ui/icons/KeyboardArrowDownOutlined";
import ArrowUp from "@material-ui/icons/KeyboardArrowUpOutlined";
type S = { showDropDownCountry: boolean, subCat2Burger:any[] ,openedItems: any, showBurgerMenu: boolean, showDropDownLanguage: boolean, scrollPosition: number, showDropDownCategori: boolean, selectedCategory: string, isSubMenuOpen: boolean, token: string, catData: any[], catAllData: CategoryData[], subCat: string[], selectedItem: SelectedItem, dropAccount: boolean, showCartData: boolean, catSug: any[], selectOption: string, selectCat: Product[], showMegaMenu: boolean, selCatId1:string, selCatId2:string, subCat2:any, subCat3:any, platform:string };
interface Props {
  handleNavigate?: () => void;
  history: any
}


const NavigationBlock = styled("div")(({ theme }) => ({
  height: "76px",
  '& .logo': {
    width: "81px",
    height: "45px",
    margin: "auto 0",
    marginLeft:"40px",
    cursor:"pointer",
    '@media (max-width: 600px)': {
      width: "50px",
      height: "30px",
      marginLeft:"0px",
    }
  },
  '& .dropdown-list': {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft:"30px",
    cursor:"pointer",
    "@media (max-width:900px)": {
      marginLeft:"0px",
    },
  },
  '& .location-icon': {
    width: "20px",
    height: "22px"
  },
  '& .down-icon': {
    cursor: "pointer",
    margin: "0px",
    "@media (max-width:900px)": {
      marginRight: "20px",
      fontSize:"30px"
    },
  },
  "& .dropdown-list-data": {
    fontFamily: "Red Hat Display",
    position: "absolute",
    maxHeight: "400px",
    top: "45px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    listStyle: "none",
    transition: "all 0.3s ease-out",
    fontSize: "16px",
    zIndex: 100,
    padding: "5px",
    cursor: "pointer",
    left: "156px",
    width: "162px",
    '@media (max-width: 600px)': {
      left: "70px !important",
    },
    '@media (max-width: 900px)': {
      left: "120px",
    }
  },
  "& .dropdown-list-data li": {
    padding: "0px 20px",
    margin: "15px 0px",
  },
  "& .dropdown-list-data li:last-of-type a": {
    border: "none",
  },
  "& .dropdown-list a": {
    margin: "4px",
    display: "inline-block",
    textDecoration: "none",
    fontSize: "16px",
    fontWeight: "400",
    color: "#FAFAFA",
    padding: " 10px 0",
    transition: "all 0.3s ease-out",
    zIndex: 100,
  },
  "& .dropdwon-box": {
    position: "relative",
    "& .dropdown-wrapper": {
      position: "absolute"
    }
  },
  "& .dropdown-item": {
    display: "flex",
    alignItems: "center"
  },
  "& .dropdown-item .selected": {
    backgroundColor: "#FFE0CC",
    color: "black",
    display: "flex",
    alignItems: "center"
  },
  "& .dropdown-item:hover": {
    backgroundColor: "#FFE0CC",
    borderRadius: "8px"
  },
  "& .dropdown-item-login .selected": {
    backgroundColor: "#FFE0CC",
    color: "black",
    display: "flex",
    alignItems: "center"
  },
  "& .dropdown-item-login:hover": {
    backgroundColor: "#FFE0CC",
    borderRadius: "8px"
  },
  "& .search-icon": {
    width: "24px",
    height: "24px",
  },
  "& .search-content": {
    color: "#94A3B8", fontFamily: 'Inter, sans-serif',
    '@media (max-width: 900px)': {
      fontSize: "10px"
    },

  },
  '& .dropdown-list-language': {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    margin: "36px 7px",
    gap: "10px",
    "@media (max-width:900px)": {
      margin: "36px 2px",
    },
  },
  "& .dropdown-list-language-logout": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    margin: "36px 31px",
    gap: "10px",
    "@media (max-width:900px)": {
      margin:"36px 10px"
    },
  },
  "& .dropdown-list-data-language": {
    top: "52px",
    fontFamily: "Red Hat Display",
    position: "absolute",
    maxHeight: "400px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    listStyle: "none",
    transition: "all 0.3s ease-out",
    fontSize: "16px",
    zIndex: 100,
    padding: "5px",
    cursor: "pointer",
    width: "162px",
    '@media (max-width: 600px)': {
      width: "100px",
    },
  },
  "& .dropdown-list-data-language li": {
    padding: "0px 20px",
    margin: "15px 0px",
  },
  "& .dropdown-list-data-language li:last-of-type a": {
    border: "none",
  },
  "& .flag-icon1": {
    width: "24px",
    "@media (max-width:900px)": {
      width:"18px"
    },
  },
  "& .flag-icon": {
    width: "24px",
  },
  '& .account-list': {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    marginRight: "10px",
  },
  "& .account-list-logout": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    margin: "21px 61px",
    gap: "10px",
  },
  "& .user-icon": {
    width: "44px",
    height: "44px"
  },
  "& .loginBox":{
    display: "block",
    '@media (max-width: 688px)': {
      display:"none"     
    },
  },
  "& .order-data": {
    margin: "35px 34px"
  },
  "& .order-data-login": {
    margin: "35px 10px",
    '@media (max-width: 700px)': {
      display:"none"
    },
  },
  "& .dropdown-list-cat": {
    fontFamily: "Red Hat Display",
    position: "absolute",
    top: "35px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    listStyle: "none",
    transition: "all 0.3s ease-out",
    fontSize: "16px",
    zIndex: 100,
    padding: "5px",
    cursor: "pointer",
    left: "83px",
    width: "228px",
  },
  "& .dropdown-list-cat li": {
    padding: "6px 20px",
  },
  "& .dropdown-list-cat li:last-of-type a": {
    border: "none",
  },
  "& .dropdown-list-cat a": {
    margin: "4px",
    display: "inline-block",
    textDecoration: "none",
    fontSize: "14px",
    fontWeight: "400",
    color: "#0F172A",
    transition: "all 0.3s ease-out",
    zIndex: 100,
  },
  "& .multi-level-dropdown": {
    fontFamily: "Red Hat Display",
    position: "relative"
  },
  "& .main-menu": {
    listStyle: "none",
    padding: "0",
  },
  "& .menu-item": {
    display: "flex",
    position: "relative",
    marginBottom: "5px",
    width: "80px",
    border: "none",
    zIndex: 100,
  },
  "& .menu-item-label": {
    cursor: "pointer",
    padding: "10px",
  },
  "& .menu-item-label-sub": {
    cursor: "pointer",
    marginLeft: "10px",
    marginRight: "50px",
    marginTop: "10px",
    marginBottom: "7px",
    width: "100%",
  },
  "& .menu-item-label-sub-data": {
    cursor: "pointer",
    fontSize: "12px",
    marginLeft: "10px",
    marginRight: "30px",
    marginTop: "15px",  
    width: "100%",
  },
  '& .menu-item:hover .sub-menu': {
    display: 'block',
  },
  '& .sub-menu': {
    position: 'relative',
    top: '36px',
    left: "402%",
    display: 'none',
    listStyle: 'none',
    padding: 0,
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
  },
  '& .sub-menu li': {
    padding: '6px 20px',
  },
  "& .upload-btn": {
    background: "#FF6600",
    minWidth: "142px",
    height: "44px",
    padding: "5px 0",
    margin: "22px 10px",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "none",
    fontFamily: 'Inter, sans-serif',
    '@media (max-width: 960px)': {
      fontSize: "10px",
      minWidth: "110px !important",
    },
    '@media (max-width: 600px)': {
      minWidth: "30px !important",
      height: "33px",
      margin: "22px 5px",
    }
  },
  "& .user-data": {
    cursor:"pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
    gap: "10px",
    
  },
  "& .dropdown-list-account": {
    fontFamily: "Red Hat Display",
    position: "absolute",
    maxHeight: "400px",
    top: "60px",
    right: "16px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    listStyle: "none",
    transition: "all 0.3s ease-out",
    fontSize: "16px",
    zIndex: 100,
    padding: "5px",
    cursor: "pointer",
    width: "162px",
  },
  "& .dropdown-list-account li": {
    padding: "3px 20px",
    margin: "15px 0px",
    gap: "16px"
  },
  "& .dropdown-list-account li:last-of-type a": {
    border: "none",
  },
  "& .cart-main": {
    fontFamily: "Red Hat Display",
    position: "absolute",
    maxHeight: "400px",
    top: "69px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    listStyle: "none",
    transition: "all 0.3s ease-out",
    fontSize: "16px",
    zIndex: 100,
    padding: "5px",
    cursor: "pointer",
    width: "497px",
    height: "361px",
    right: "10px",
    "@media (max-width:900px)": {
      width:"90vw",
      top: "90px",
      right: "10px",
    },
  },
  "& .close-btn": {
    display: "flex",
    flexDirection: "row-reverse",
    marginTop: "31px",
    marginRight: "34px",
  },
  "& .view-btn": {
    border: "1px solid #FF6600",
    width: "405px",
    height: "50px",
    borderRadius: "10px",
    marginLeft: "10px",
    marginTop: "10px"
  },
  "& .input-step": {
    display: "inline-flex",
    width: "90px",
    overflow: "visible",
    padding: "4px",
    border: "1px solid #94A3B8",
    height: "30px",
    borderRadius: "8px",
    "@media (max-width:900px)": {
      marginLeft:"-25px"
    },
  },
  "& .input-step input": {
    background: "#0000",
    border: "0",
    color: "black",
    height: "100%",
    textAlign: "center",
    width: "4em"
  },
  "& .search-input": {
    margin: "0 10px",
    display: "flex",
    flexDirection: "row",
    border: "1px solid #CBD5E1",
    padding: "0px 12px",
    width: "100%",
    height: "40px",
    justifyContent: "space-between",
    borderRadius: "10px",
    alignItems: "center",
    '@media (max-width: 600px)': {
      '& .MuiTypography-body1': {
        fontSize: "10px !important"
      }
    }
  },
  "& .selectBtn":{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    borderRadius:"9px",
    backgroundColor:"#F3F4F6",
    padding:"12px 16px",
    width:"200px",
    marginLeft:"16px",
    cursor:"pointer",
    "@media (max-width:900px)": {
      padding:"12px 0px",
      width:"120px",
      marginLeft:"5px",
    },
  },
  "& .selectBtn-text":{
    fontFamily:"Inter, sans-serif",
    fontWeight:400,
    fontSize:"12px",
    "@media (max-width:900px)": {
      fontSize:"8px",
    },
  },
  "& .selectCategoryBtn":{
    display:"flex",
    justifyContent:"space-around",
    alignItems:"center",
    gap:"12px",
    marginRight:"10px",
    "@media (max-width:900px)": {
      gap:"0px",
      marginRight:"0px",
    },
  },
  "& .selectCategoryBtn-text":{
    fontFamily:"Inter, sans-serif",
    fontWeight:400,
    fontSize:"12px",
    color:"#171A1F"
  },
  "& .userTool":{
    height:"20px",
    cursor:"pointer"
  },
  "& .userTools":{
    display:"flex",
    justifyContent:"space-around",
    width:"220px"
  },
  "& .userInitials":{
    fontFamily:"Inter, sans-serif",
    fontWeight:700,
    fontSize:"13px",
    color:"#EE6900",
    backgroundColor:"#FFF6F0",
    padding:"10px 10px",
    borderRadius:"9px",
    marginLeft:"55px",
    "@media (max-width:900px)": {
      marginLeft:"5px",
    },
  },
  "& .userName":{
    fontFamily:"Inter, sans-serif",
    fontWeight:700,
    fontSize:"13px",
    color:"#171A1F",
    whiteSpace:"nowrap"
  },
  "& .burgerShadow":{
    backgroundColor:"rgba(0, 0, 0, 0.3)",
    width:"100vw",
    height:"100vh",
    position:"fixed",
    top:"0px",
    zIndex:12,
    opacity: 1, 
  },
  "& .burgerItems":{
    width:"80vw",
    height:"100vh",
    background:"#FFF",
    paddingLeft:"20px",
    paddingTop:"20px",
    display:"flex",
    flexDirection:'column',
    overflowY:"scroll"
  },
  "& .subNavBtn":{
    display:"flex",
    alignItems:"center",
    margin:"0px 10px",
    "@media (max-width:900px)": {
      display:"flex",
      justifyContent:"space-between",
      margin:"10px 0px",
      flexDirection:"row",
      width:"100%",
      fontSize: "24px",
      marginRight:"20px",
    },
  },
  "& .closeBurger":{
    marginLeft: "auto",
    marginRight:"20px"
  },
  "& .burgerCategory":{
    fontSize: "24px", 
    fontWeight: 700, 
    fontFamily: 'Inter, sans-serif',
    color:"#1E293B",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    paddingRight:"20px",
    cursor:"pointer"
  },
  "& .burgerCategory1":{
    fontSize: "20px", 
    fontWeight: 600, 
    fontFamily: 'Inter, sans-serif',
    color:"#1E293B",
    paddingLeft:"10px",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    paddingRight:"20px",
    cursor:"pointer"
  },
  "& .burgerCategory2":{
    fontSize: "18px", 
    fontWeight: 500, 
    fontFamily: 'Inter, sans-serif',
    color:"#1E293B",
    paddingLeft:"15px",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    paddingRight:"0px",
    cursor:"pointer"
  },
  "& .burgerCategory3":{
    fontSize: "16px", 
    fontWeight: 500, 
    fontFamily: 'Inter, sans-serif',
    color:"#1E293B",
    paddingLeft:"20px",
    cursor:"pointer"
  },
}
))

const SubNavigationBlock = styled("div")(({ theme }) => ({
  "& .right-left-icon": {
    cursor: "pointer",
    fontSize: "30px",
    fontWeight: "bold",
    '@media (max-width: 600px)': {
      fontSize: "20px"
    }
  },
  "& .down-icon": {
    '@media (max-width: 600px)': {
      fontSize: "15px"
    }
  },
  "& .main-block": {
    backgroundColor: "#ECEEF1",
    marginTop: "10px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    "@media (max-width:900px)": {
      flexWrap: 'wrap',
      padding: '5px 0px',
      height: 'auto',
      width: '100vw',
    },
  },
  "& .category-content": {
    margin: "4px 40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "110px",
    padding: "5px 18px",
    cursor: "pointer",
    '@media (max-width: 600px)': {
      '& .MuiTypography-body1': {
        fontSize: "10px !important"
      },
      margin: "17px 0 17px 10px",
    }

  },
  "& .category-content-text":{
    fontSize: "12px", 
    fontWeight: 700, 
    fontFamily: 'Inter, sans-serif',
    color:"#1E293B"
  },
  "& .horizontal-scroll-menu": {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    width: " 67%",
    "@media (max-width:900px)": {
      width: "60%",
    },
  },
  "& .scroll-container": {
    display: "flex",
    overflowX: "hidden",
    scrollBehavior: "smooth",
    whiteSpace: "nowrap",
    flexGrow: 1,
    cursor: "pointer",
    '@media (max-width: 600px)': {
      '& .MuiTypography-body1': {
        fontSize: "10px !important"
      },
    }
  },
  "& .menu-item": {
    display: "inline-block",
    marginRight: "10px",
    fontSize: "12px",
    fontWeight: 500,
    padding: "10px",
    paddingLeft: 0,
  },
  "& .menu-item-scr": {
    display: "inline-block",
    marginRight: "10px",
    fontSize: "16px",
    fontWeight: 500,
    padding: "10px",
    paddingLeft: 0,
    color:"#565E6C"
  },
  "& .menu-item-scr-active": {
    display: "inline-block",
    marginRight: "10px",
    fontSize: "16px",
    fontWeight: 700,
    padding: "10px",
    paddingLeft: 0,
    color:"#1E293B"
  },
  "& .scroll-buttons": {
    margin: "10px",
    "@media (max-width:900px)": {
      margin: "5px",
    },
  },
  "& .subNavBtn":{
    display:"flex",
    alignItems:"center",
    margin:"0px 10px",
    "@media (max-width:900px)": {
      display:"flex",
      margin:"0px 0px",
      flexDirection:"row",
      width:"100%"
    },
  },
  "& .dropdown-megamenu":{
    position:"absolute",
    top:"135px",
    left:"10vw",
    width:"80vw",
    minHeight: "50px",
    height:"auto",
    backgroundColor:"#FFF",
    padding:"30px",
    borderRadius:"0px, 0px, 8px, 8px",
    border: "1px solid #E2E8F0",
    boxShadow:" -4px 0px 4px rgba(0, 0, 0, 0.08), 4px 4px 8px rgba(0, 0, 0, 0.08)",
    "@media (max-width:900px)": {
      top:"0px",
      left:"0vw",
      position:"fixed",
      width:"80vw",
      height:"100vh",
      overflowY:"scroll"
    },
  },
  "& .mobileShadow":{
    "@media (max-width:900px)": {
      width:"100vw",
      height:"200vh",
      position:"fixed",
      backgroundColor:"rgba(0, 0, 0, 0.5)",
      zIndex:10
    },
    
  },
  "& .megamenu-main-cat":{
    fontSize: "14px", 
    fontWeight: 600, 
    fontFamily: 'Inter, sans-serif',
    color:"#1E293B",
    cursor:"pointer",
    marginBottom:"8px"
  },
  "& .megamenu-sub-cat":{
    fontSize: "14px", 
    fontWeight: 400, 
    fontFamily: 'Inter, sans-serif',
    color:"#0F172A",
    cursor:"pointer",
    margin:"8px 0px"
  },
  "& .megamenu-cat":{
    marginBottom:"50px"
  },
  "& .bulkOrder":{
    fontWeight: 700, fontFamily: 'Inter, sans-serif', fontSize:"12px", color:"#1E293B",
    whiteSpace:"nowrap",
    cursor:"pointer",
    "@media (max-width:900px)": {
      fontSize:"20px",
      whiteSpace:"wrap",
    },
  }
}
))

const NavigationBlockWOLogin = styled("div")(({ theme }) => ({
  fontFamily: 'Inter, sans-serif',
  "& .main-content": {
    display: "flex",
    flexDirection: "column",
    overflow: 'hidden'
  },
  "& .logo":{
    cursor:"pointer",
    width:"80px",
    height:"45px",
    "@media (max-width:900px)": {
      width:"60px",
      height:"35px",
    },
  },
  "& .navText":{
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: 'Inter, sans-serif',
    color:"#323842",
    marginLeft:"90px",
    textAlign:"center",
    "@media (max-width:900px)": {
      marginLeft:"0px",
      fontSize: "14px",
    }},
    "& .navText__text":{
      cursor:"pointer",
      "@media (max-width:900px)": {
        padding:"8px"
      },        
    },
    "& .navText__text-active":{
      cursor:"pointer",
      fontWeight:600,
      "@media (max-width:900px)": {
        padding:"8px"
      },        
    },
  "& .navMenu":{
    marginTop:"17px",
  },
  "& .navTextAndLogo":{
    marginLeft:"40px",
    "@media (max-width:900px)": {
      marginLeft:"10px",
    },
  },
  "& .loginBtn":{
    color: "#FFFFFF",
    width:"85px",
    height:"32px",
    backgroundColor:"#FF6600",
    borderRadius:"8px",
    marginRight:"40px",
    fontSize: "14px",
    fontWeight: 500,
    display:"flex",
    alignItems:"center",
    padding:"6px 14px",
    borderColor:"transparent",
    cursor:"pointer",
    "@media (max-width:900px)": {
      width:"60px",
      height:"32px",
      marginRight:"10px",
      marginBottom:"5px",
      padding:"6px 2px",
      fontSize: "12px",
    }
  }}));

interface SS {
  id: any;
}

interface Image {
  file_name: string;
  content_type: string;
  id: number;
  url: string;
}

interface ProductAttributes {
  id: number;
  name: string;
  brand_name: string;
  price: number | null;
  prices: number[];
  change: string;
  change_up: boolean;
  description: string;
  manufacturer: string;
  specification: string;
  applications: string;
  certifications: string;
  is_recommended: boolean;
  status: string;
  product_id: string;
  images: Image[];
  category: string;
  sub_category: string;
  brand: string;
  updated: string;
  is_new: string;
  is_best_seller: boolean;
  product_feature: {
      data: string[];
  };
}

interface Product {
  id: string;
  type: string;
  attributes: ProductAttributes;
}

interface SelectedItem {
  value: string;
  label: string;
}
interface Product {
  id: string;
  name: string;
  change: number | null;
  brand_name: string;
  price: number | null;
  created_at: string;
  updated_at: string;
  change_up: boolean;
  description: string;
  specification: string;
  is_recommended: boolean;
  applications: string;
  certifications: string;
  category_id: number;
  sub_category_id: number;
  manufacturer: string | null;
  pid: number | null;
}
interface CategoryAttributes {
  id: number;
  name: string;
  category_id: string;
  image: string;
  sub_categories: any[];
}
interface CategoryData {
  id: string;
  type: string;
  attributes: CategoryAttributes;
}
interface SubProduct {
  id: number;
  name: string;
}
interface SubCategory {
  id: number|string;
  name: string;
  created_at: string;
  updated_at: string;
  parent_id: number | null;
  rank: number | null;
  products: SubProduct[];
}

class Navbar extends BlockComponent<Props, S, SS>{
  getCatList: string = "";
  getSugCatList: string = "";
  getSugCatList2: string = "";
  getSugCatList3: string = "";
  getSugCatList2BurgerReceive: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      showDropDownCountry: false,
      showDropDownLanguage: false,
      scrollPosition: 0,
      showDropDownCategori: false,
      selectedCategory: "",
      isSubMenuOpen: false,
      token: "",
      catData: [],
      catAllData: [],
      subCat: [],
      selectedItem: { value: "", label: "" },
      catSug: [],
      selectOption: "",
      selectCat: [],
      dropAccount: false,
      showCartData: false,
      showMegaMenu: false,
      showBurgerMenu:false,
      selCatId1:"69",
      selCatId2:"69",
      subCat2:[],
      subCat2Burger:[],
      subCat3:[],
      platform:'desktop',  
      openedItems: {},    
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    await super.componentDidMount();
    const token = (typeof localStorage !== "undefined" && localStorage.getItem('otp-token')) || '';
    this.setState({ token: token })
    await this.getCategory();
    this.getSuggesionCategory();
    this.updateWindowDimensions();
    await this.handleSubBurgerMenu()
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions = () => {
    if(window.innerWidth<=900){
      this.setState({
      platform:'mobile'
    });
    }
    
  };
  handleBurgerMenu = () =>{
    if(this.state.platform=='mobile'){
      return <MenuIcon className='userTool' data-testid='burger-icon' onClick={()=>{this.setState({showBurgerMenu:true})}}/>
    }else{
      return <img className='userTool' src={require("./headerHome.png")} alt="" onClick={()=>{this.handlePagesClick("logo")}} />
    }
  }
  handleToggleBurger = ()=>{
    this.setState({showBurgerMenu:!this.state.showBurgerMenu})
  }
  toggleItem = (index:any) => {
    this.setState((prevState) => ({
      openedItems: {
        ...prevState.openedItems,
        [index]: !prevState.openedItems[index],
      },
    }));
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId == this.getCatList) {
      const categoryData = responseJson?.data?.data?.map((category: CategoryData) => category.attributes)
      const cat = responseJson?.data?.data?.map((category: CategoryData) => category.attributes)
      this.setState({ catData: categoryData, catAllData: cat }, ()=>{
        this.handleSubBurgerMenu()
      })
    }
    // Customizable Area Start
    if (apiRequestCallId == this.getSugCatList) {
      const data = responseJson?.data?.map((cat: { attributes: {id: string, name:string} }) => {

        return { value: cat.attributes.id, label: cat.attributes.name }

      })
      this.setState({ catSug: data, subCat:responseJson?.sub_categories?.data})
      if (this.state.selectOption) {

        let specificationValues: object[] = [];

        responseJson.data.forEach((item: Product ) => {



          const specifications = JSON.parse(item.attributes.specification);



          Object.entries(specifications).forEach(([key, value]) => {

            specificationValues.push({ value: value, label: value })

          });

          const catDataId = responseJson.data.map((datas:{ attributes: {id: string} }) => {
            return datas.attributes.id
          })
          this.setState({ catSug: specificationValues, selectCat: catDataId[0] });

        });

      }
      // Added a nullish check for 'catSug' to prevent potential errors that occur
      // when this property is undefined or null, which could result in a blank screen.
      // The optional chaining operator (?.) ensures that we safely access the 'length'
      // property only if 'catSug' exists, avoiding runtime exceptions.
      if (this.state.catSug?.length === 0) {

        this.handleClick("name")

      }
      // Customizable Area End
    }
    if (apiRequestCallId == this.getSugCatList2) {
        
      const data = responseJson?.data?.map((cat: { attributes: {id: string, name:string} }) => {

        return { value: cat.attributes.id, label: cat.attributes.name }

      })

      this.setState({ catSug: data, subCat2:responseJson?.sub_categories?.data })


      if (this.state.catSug?.length === 0) {

        this.handleClick("name")

      }
      // Customizable Area End
    }
    if (apiRequestCallId == this.getSugCatList2BurgerReceive) {
        
      const data = responseJson?.data?.map((cat: { attributes: {id: string, name:string} }) => {

        return { value: cat.attributes.id, label: cat.attributes.name }

      })

      this.setState({ catSug: data, subCat2:responseJson?.sub_categories?.data })
      this.setState(prevState => ({
        subCat2Burger: Array.isArray(prevState?.subCat2Burger) 
          ? [...prevState.subCat2Burger, ...(responseJson?.sub_categories?.data || [])]
          : [...(responseJson?.sub_categories?.data || [])]
      }));


      if (this.state.catSug?.length === 0) {

        this.handleClick("name")

      }
      // Customizable Area End
    }
    if (apiRequestCallId == this.getSugCatList3) {
      this.setState(prevState => ({
        subCat3: Array.isArray(prevState?.subCat3) 
          ? [...prevState.subCat3, ...(responseJson?.sub_categories?.data || [])]
          : [...(responseJson?.sub_categories?.data || [])]
      }));

     

      if (this.state.catSug?.length === 0) {

        this.handleClick("name")

      }
    }
  }
  handleSelectChange = (selectedOption: any, actionMeta: any) => {
    if (selectedOption) {
      const selectedLabel = selectedOption.map((a: { label: string, value: string }) => {
        return a.label
      })
      const selectedItem = this.state.catSug.find((item: { value: string, label: string }) => item.label === selectedLabel[0])
      const specificationData = selectedItem?.value;
      this.setState({ selectOption: specificationData }, () => {
        this.getSuggesionCategory();
      });
    }
  };
  handleDropdownClick = (label: any) => {
    this.setState({
      showDropDownCategori: true,
      selectedItem: label.name
    });  
  };

  handleCategoryClick = (label: any) => {    
    const subCategories = label.sub_categories.map((subCategory: SubCategory) => subCategory);   
    this.setState((prevState: any) => ({
      isSubMenuOpen: true,
      subCat: subCategories,
      selectedItem: label.name
    }));  
  };

  handleAccountClick = (label: any) => {
    if (label === "Logout") {
      localStorage.removeItem('otp-token');
      localStorage.clear();
      if(this.props.history.location.pathname=='/'){
        this.props.history.push("/LandingPage")
      }else{
        this.props.history.push("/")
      }      
    }
    if (label === "Settings") {
      this.props.history.push("/UserCategories")
    }
  };

  handlePagesClick = (label: any) => {
    if (label === "platform") {
      this.props.history.push("/Platform");
    }
    if (label === "materials") {
      this.props.history.push("/Price")
    }
    if (label === "news") {
      this.props.history.push("/News")
    }
    if (label === "logo") {
      this.props.history.push("/")
    }
  };
  
  handleloginClick = ()=>{
    this.props.history.push("/Signuplogin")
  };

  handleArrowcategory = (condition: any, fontSize: any) => {
    if (condition) {
      return <ArrowUp fontSize={fontSize} />;
    } else {
      return <ArrowDown fontSize={fontSize} />;
    }
  }

  handleDropdownOpen = () => {
    this.setState({ showDropDownCountry: !this.state.showDropDownCountry })
  }

  handleDropdownLanguageOpen = () => {
    this.setState({ showDropDownLanguage: !this.state.showDropDownLanguage })
  }

  handleAccountOpen = () => {
    this.setState({ dropAccount: !this.state.dropAccount })
  }

  handleDrodownCat = () => {
    this.setState({ showDropDownCategori: !this.state.showDropDownCategori })
  }

  handleScroll = (scrollType: string) => {
    const container = document.getElementById('scroll-container');
    const scrollAmount = 200;
    const newPosition =
      scrollType === 'right'
        ? this.state.scrollPosition + scrollAmount
        : this.state.scrollPosition - scrollAmount;

    if (container) {
      container.scrollTo({
        top: 0,
        left: newPosition,
        behavior: 'smooth',
      });
    }

    this.setState({ scrollPosition: newPosition })
  };

  handleClick = (name: any) => {

    this.props.history.push({
      pathname: '/Catalogue',
      state: {
        breadCrum: [
          { name: "Home", path: "LandingPage" },
          { name: "Categories", path: "LandingPage" },
          { name: name, path: "Catalogue" },
        ],
        headerText: name,   
        selectCat: this.state.selectCat
              },
    });
      };

    sleep(ms:any) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    handleSubBurgerMenu = async () => {
      for (const elem of this.state.catData) {
        await this.getSuggesionCategory2Burger(elem.id);
        await this.sleep(300);
    
        if (this.state.subCat2) {
          for (const subElem of this.state.subCat2) {
            const idExists = this.state.subCat3?.some(
              (subCat3Elem: any) => subCat3Elem.attributes.parent_category_id === subElem.attributes.id
            );
            
            if (!idExists) {
              await this.getSuggesionCategory3(subElem.attributes.id);
              await this.sleep(200);
            }
          }
        }
      }
    };
    
    onMouseEnter = async (prd: any) =>{
      this.setState({selCatId1:prd.id});
      await this.sleep(100);
      await this.getSuggesionCategory2();
      await this.sleep(200);
     if(this.state.subCat2){
      for (const elem of this.state.subCat2) {
        const idExists = this.state.subCat3?.some((subCat3Elem:any) => subCat3Elem.attributes.parent_category_id == elem.attributes.id);        
        if (!idExists) {
          await this.getSuggesionCategory3(elem.attributes.id);
          await this.sleep(200);
        }
      }}
      this.setState({showMegaMenu:true});
    }
    onMouseLeave = () =>{
      this.setState({showMegaMenu:false})
    }

  cartOpen = () => {
    this.setState({ showCartData: !this.state.showCartData })
  }

 
  getCategory = async () => {
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCatList = getValidationsMsg.messageId
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_categories/get_categories")
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getSuggesionCategory = async () => {
    const headers = {

      "Content-Type": "application/json",

      token: this.state.token,

    };

    const getValidationsMsg = new Message(

      getName(MessageEnum.RestAPIRequestMessage)

    );

    this.getSugCatList = getValidationsMsg.messageId


  getValidationsMsg.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),
  
      `bx_block_productdescription/products/search?query[]=${this.state.selectOption}`)
      

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(headers)

    );

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),

      "GET"

    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

  }

  getSuggesionCategory2 = async () => {
    const headers = {

      "Content-Type": "application/json",

      token: this.state.token,

    };

    const getValidationsMsg = new Message(

      getName(MessageEnum.RestAPIRequestMessage)

    );

    this.getSugCatList2 = getValidationsMsg.messageId


  getValidationsMsg.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),
  
      `bx_block_categories/get_sub_categories?parent_category_id=${this.state.selCatId1}`)
      

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(headers)

    );

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),

      "GET"

    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

  }
  getSuggesionCategory2Burger = async (id:any) => {
    const headers = {

      "Content-Type": "application/json",

      token: this.state.token,

    };

    const getValidationsMsg = new Message(

      getName(MessageEnum.RestAPIRequestMessage)

    );

    this.getSugCatList2BurgerReceive = getValidationsMsg.messageId


  getValidationsMsg.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),
  
      `bx_block_categories/get_sub_categories?parent_category_id=${id}`)
      

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(headers)

    );

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),

      "GET"

    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

  }

  getSuggesionCategory3 = async (id:string) => {
    const headers = {

      "Content-Type": "application/json",

      token: this.state.token,

    };

    const getValidationsMsg = new Message(

      getName(MessageEnum.RestAPIRequestMessage)

    );

    this.getSugCatList3 = getValidationsMsg.messageId


  getValidationsMsg.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),
  
      `bx_block_categories/get_sub_categories?parent_category_id=${id}`)
      

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(headers)

    );

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),

      "GET"

    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

  }
  checkActivePage = (pageLocation:string, className:string) =>{
    const activePage = this.props.history.location.pathname;
    if(activePage == pageLocation){
      className = `${className}-active`
    }
    return className
  }

  render() {
    const menuItems = [
      { label: "India" },
      { label: "USA" },
      { label: "Canada" },
    ];

    const languageItems = [
      { label: "English" },
      { label: "Spanish" },
    ];

    const accountItems = [
      { label: "My Profile", image: require("./profile.jpg") },
      { label: "Dashboard", image: require("./dashboard.jpg") },
      { label: "Settings", image: require("./setting.jpg") },
      { label: "Logout", image: require("./logout.jpg") },
    ];
    const { openedItems } = this.state;

    const CustomOption = ({ data, ...props }: any) => {
      const isHeading = data.value === 'heading';
      return (
        <components.Option
          {...props}
          className={isHeading ? 'heading-option' : ''}
          isDisabled={isHeading}
        >
          <div className="menu-options">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}>
              {isHeading ? null : <img src={require("./search_sug.png")} alt="search icon" />}
              <span style={{ fontSize: "14px", fontWeight: 400, fontFamily: 'Inter, sans-serif', color: isHeading ? "black" : "inherit" }}>{data.label}</span>
            </div>
            {isHeading ? null : <img style={{ height: "14px", width: "14px" }} src={require("./receive.png")} alt="receive icon" />}
          </div>
        </components.Option>
      );

    };
    const ClearableMultiValueRemove: React.FC<ComponentProps<typeof components.MultiValueRemove>> = (props) => {
      const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.setState({ selectOption: "" }, () => {
          this.getSuggesionCategory();
        });
        e.stopPropagation();
        props.innerProps.onClick(e);
      };

      return (
        <components.MultiValueRemove {...props}>
          <div onMouseDown={handleMouseDown}>
            &#10005;
          </div>
        </components.MultiValueRemove>
      );
    };
    // Added this check to ensure the existence of 'catSug' property in order to prevent rendering errors and the blank screen issue on the site
    let optionsWithHeading;
    if(this.state.catSug){
      optionsWithHeading = [
        { value: 'heading', label: 'Suggestions', isDisabled: true, isHeading: true },
        ...this.state.catSug
      ];
    }else{
     optionsWithHeading = [
        { value: 'heading', label: 'Suggestions', isDisabled: true, isHeading: true }
      ];
    }
    
    return (
      <>
      {this.state.token ? (
        <>
          <NavigationBlock>
            <Box sx={webStyle.navbar}>
              <Box sx={{ display: 'flex', }}>
                <svg width="214" height="90" viewBox="0 0 214 90" fill="none" xmlns="http://www.w3.org/2000/svg" className='logo' onClick={()=>{this.handlePagesClick("logo")}}>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M62.3359 89.9789H86.6248L63.1464 48.1662L46.3133 62.5845L62.3359 89.9789ZM82.5675 19.4618L105.105 0.0195312H81.9726L32.4795 42.0431L42.1331 0.0195312H21.4415L0.777344 89.9789H21.4688L26.6934 67.2322L32.1911 62.6073L82.5675 19.4618Z" fill="#1B1918"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M194.745 89.9789L173.919 47.591L159.447 61.6677L158.176 63.061L170.714 89.9789H194.745ZM175.817 39.3307L213.224 0.0195312H190.734L167.691 24.9125L168.326 26.2377L175.817 39.3307Z" fill="#1B1918"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M141.1 43.4578L132.939 29.1929L118.949 0.0195312H95.3036L116.763 44.2289L72.9688 89.9789H96.8722L125.379 58.7503L141.1 43.4578Z" fill="#FF6600"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M171.889 43.4578L163.728 29.1929L149.738 0.0195312H126.093L147.552 44.2289L103.758 89.9789H127.661L156.168 58.7503L171.889 43.4578Z" fill="#FF6600"/>
                  </svg>
                <Box className='dropdown-list' onClick={this.handleDropdownOpen}>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.857 1.42871C11.6635 1.42871 10.4549 1.818 9.45703 2.53585C12.0856 3.42585 14.2856 5.808 14.2856 9.28585C14.2856 11.2037 13.1156 13.3744 11.8099 15.2059C12.0685 15.4837 12.2656 15.6866 12.3499 15.7716C12.4842 15.9066 12.6663 15.9823 12.857 15.9823C13.0477 15.9823 13.2299 15.9066 13.3642 15.7716C13.897 15.2337 18.5713 10.4287 18.5713 7.143C18.5713 3.56585 15.6663 1.42871 12.857 1.42871Z" fill="#323842"/>
                  <path d="M7.14202 3.57129C4.33273 3.57129 1.42773 5.70843 1.42773 9.28557C1.42773 12.5713 6.10202 17.3763 6.63488 17.9141C6.76916 18.0491 6.95131 18.1249 7.14202 18.1249C7.33273 18.1249 7.51488 18.0491 7.64916 17.9141C8.18202 17.3763 12.8563 12.5713 12.8563 9.28557C12.8563 5.70843 9.95131 3.57129 7.14202 3.57129ZM7.14202 10.7141C6.35273 10.7141 5.71345 10.0749 5.71345 9.28557C5.71345 8.49629 6.35273 7.857 7.14202 7.857C7.93131 7.857 8.57059 8.49629 8.57059 9.28557C8.57059 10.0749 7.93131 10.7141 7.14202 10.7141Z" fill="#323842"/>
                  </svg>
                </Box>
                <Box className='selectBtn'>
                  <Typography className='selectBtn-text'>Select Company & Project</Typography>
                  <img className='down-icon' src={require("./down_arrow.jpg")} alt="down_arrow" />
                </Box>
                <Box sx={webStyle.searchContent}>
                  <Box className='selectCategoryBtn'>
                    <Typography className='selectCategoryBtn-text'>Category</Typography>
                    <img className='down-icon' src={require("./down_arrow.jpg")} alt="down_arrow" />
                  </Box>
                  <Select
                    isMulti
                    name="select"
                    options={optionsWithHeading}
                    className="basic-multi-select"
                    components={{Option: CustomOption, MultiValueRemove: ClearableMultiValueRemove }}
                    placeholder="Search the marketplace"
                    onChange={this.handleSelectChange}
                  />
                  <Box className='searchIco'>
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className='searchIco'>
                    <path d="M7.3577 12.0001C4.51713 12.0001 2.21484 9.69778 2.21484 6.85721C2.21484 4.01664 4.51713 1.71436 7.3577 1.71436C10.1983 1.71436 12.5006 4.01664 12.5006 6.85721C12.4971 9.69607 10.1966 11.9966 7.3577 12.0001ZM7.3577 2.85721C5.14856 2.85721 3.3577 4.64807 3.3577 6.85721C3.3577 9.06636 5.14856 10.8572 7.3577 10.8572C9.56684 10.8572 11.3577 9.06636 11.3577 6.85721C11.3554 4.64921 9.5657 2.8595 7.3577 2.85721Z" fill="#9095A0"/>
                    <path d="M14.6189 13.3104L12.1869 10.8784C11.9429 11.1716 11.672 11.4424 11.3789 11.6864L13.8109 14.1184C14.0378 14.3378 14.3995 14.3316 14.6189 14.1041C14.8326 13.8824 14.8326 13.5316 14.6189 13.3104Z" fill="#9095A0"/>
                    <path d="M5.07143 7.42857C4.756 7.42857 4.5 7.17257 4.5 6.85714C4.5 5.28171 5.78171 4 7.35714 4C7.67257 4 7.92857 4.256 7.92857 4.57143C7.92857 4.88686 7.67257 5.14286 7.35714 5.14286C6.412 5.14286 5.64286 5.912 5.64286 6.85714C5.64286 7.17257 5.38686 7.42857 5.07143 7.42857Z" fill="#9095A0"/>
                    </svg>
                  </Box>
                  
                </Box>
              </Box>
              <Box className='userTools'>
                    {this.handleBurgerMenu()}
                    <img className='userTool' src={require("./headerNotification.png")} alt="" />
                    <img className='userTool' src={require("./headerRFQ.png")} alt="" />
                    <img className='userTool' src={require("./headerFlash.png")} alt="" />
                    <Box className='account-list'>
                    <img className='userTool' src={require("./headerCard.png")} alt="" onClick={this.cartOpen}/>
                      <Hidden>
                        {this.state.showCartData && (
                          <Box className="cart-main">
                            <Box className='close-btn'>
                              <img src={require("./close.jpg")} alt="close" onClick={this.cartOpen} />
                            </Box>

                            <Box style={{ display: "flex", margin: "10px 16px" }}>
                              <img src={require("./helmate.jpg")} />
                              <Box style={{ gap: "10px", marginLeft: "20px" }}>
                                <Box style={{ display: "flex", gap: "10px" }}>
                                  <img src={require("./corretc.jpg")} alt="close" />
                                  <Typography style={{ fontSize: "13px", fontWeight: 500, color: "#059669" }}>Added to cart</Typography>
                                </Box>
                                <Box style={{ marginTop: "10px" }}>
                                  <Typography style={{ fontSize: "13px", fontWeight: 500, color: "black" }}>Valpro - Premium Plastic Helmet...</Typography>
                                  <Typography style={{ marginTop: "10px", fontSize: "14px", fontWeight: 600 }}>$66</Typography>
                                </Box>
                                <Box style={{ marginTop: "10px", display: "flex", gap: "42px" }}>
                                  <Typography style={{ fontSize: "13px", fontWeight: 500, color: "black" }}>Quantity</Typography>
                                  <div className="input-step">
                                    <button style={{ backgroundColor: "white", border: "none" }} type="button" className="minus material-shadow">-</button>
                                    <input name="demo_vertical" readOnly type="text" className="product-quantity form-control" value="1" />
                                    <button style={{ backgroundColor: "white", border: "none" }} type="button" className="plus material-shadow">+</button>
                                  </div>
                                </Box>
                                <Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box style={{ marginTop: "5px", display: "flex", marginLeft: "10px", marginRight: "10px" }}>
                              <Checkbox style={{ width: "20px", borderRadius: "6px" }}
                                data-test-id="check-btn"
                                className="check-btn"
                              />
                              <Typography style={{ fontSize: "13px", fontWeight: 700, color: "#334155" }}>Selecting if purchasing for Business (for GST Details Later)</Typography>
                            </Box>
                            <Box style={{ marginTop: "24px", display: "flex", alignItems: "center", justifyContent: "center" }} >
                              <Button className='view-btn'><img style={{ marginLeft: "4px" }} src={require("./eye.jpg")} alt="upload" />View cart</Button>
                            </Box>
                          </Box>
                        )}
                      </Hidden>
                    </Box>
                    <img className='userTool' src={require("./headerChat.png")} alt="" />
                  </Box>
                <Box sx={{ display: "flex", justifyContent: 'space-around', alignItems: 'center' }}>
                  <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Box className='user-data' onClick={this.handleAccountOpen}>
                      <Typography className='userInitials'>
                        MG
                      </Typography>
                      <Typography className='userName'>
                        Mihir Group
                      </Typography>
                    </Box>
                    <Hidden>
                      {this.state.dropAccount && (
                        <ul className="dropdown-list-account">
                          {accountItems.map((item) => (
                            <li
                              key={item.label}
                              id="dropdowndata"
                              className={`dropdown-item ${this.state.selectedItem.label == item.label ? 'selected' : ''}`}
                              data-test-id="dropdowndata"
                              onClick={() => this.handleAccountClick(item.label)}
                            >
                              <span><img src={item.image} /></span>
                              <span>{item.label}</span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </Hidden>
                  </Box>
                </Box>
              </Box>
            <Hidden>
              {this.state.showDropDownCountry && (
                <ul className="dropdown-list-data">
                  {menuItems.map((item) => (
                    <li
                      key={item.label}
                      id="dropdowndata"
                      className="dropdown-item"
                      data-test-id="dropdowndata"
                      onClick={() => this.handleDropdownClick(item.label)}
                    >
                      <span>{item.label}</span>
                    </li>
                  ))}
                </ul>
              )}
            </Hidden>
            <Hidden>
              {this.state.showBurgerMenu && (
                <div className="burgerShadow" data-testid='burger-menu'>
                  <div className="burgerItems">
                    <CloseIco fontSize="large" className="closeBurger" data-testid='close-burger-icon' onClick={this.handleToggleBurger} />
                    <Box className="subNavBtn">
                      <Box sx={webStyle.bulkOrder}>Customized Products</Box>
                      <ExpandMore className="down-icon" />
                    </Box>
                    <Box className="subNavBtn">
                      <Box sx={webStyle.bulkOrder} className='bulkOrder'>Hire Pro</Box>
                      <ExpandMore className="down-icon" />
                    </Box>
                    <Box className="subNavBtn">
                      <Box sx={webStyle.bulkOrder}>Audit Report</Box>
                    </Box>
                    <div className="opened-for-codepen">
                      <h2 className='burgerCategory' onClick={() => {this.toggleItem(0)}}>Categories {this.handleArrowcategory(this.state.openedItems[0], 'large')}</h2>
                      {this.state.openedItems[0] && (this.state.catData?.map((elem1: any, i: number) => {
                          return (
                            <div key={elem1.id} className={this.state.openedItems[i + 1] ? "opened-for-codepen" : ""}>
                              <h3 className='burgerCategory1' onClick={() => this.toggleItem(elem1.id)}>{elem1.name} {this.handleArrowcategory(this.state.openedItems[elem1.id], 'medium')}</h3>
                              {this.state.openedItems[elem1.id] && (
                                <div>
                                  {this.state.subCat2Burger?.filter((elem2: any) => elem2?.attributes?.parent_category_id === elem1.id).map((elem: any, id: number) => {
                                    return (
                                      <Grid item key={id} xs={11} sm={11} md={2} lg={2} className="megamenu-cat">
                                        <h4 className='burgerCategory2' onClick={() => this.toggleItem(elem.attributes.id)}>{elem.attributes.name} {this.handleArrowcategory(this.state.openedItems[elem.attributes.id], 'small')}</h4>
                                        {this.state.subCat3 &&
                                          this.state.subCat3
                                            .filter((elem3: any) => elem3?.attributes?.parent_category_id === elem.attributes.id)
                                            .map((e: any) => {
                                              return this.state.openedItems[elem.attributes.id] && <p key={e.id} className='burgerCategory3' onClick={() => {this.handleClick(elem.attributes.name); this.setState({showBurgerMenu:false})}}>{e.attributes.name}</p>;
                                            })}
                                      </Grid>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          );
                        }))}
                    </div>
                  </div>
                </div>
              )}
            </Hidden>
            <div className="multi-level-dropdown">
              <ul className="main-menu">
                <li className="menu-item">
                  <Hidden >
                    {this.state.showDropDownCategori && (
                      <ul className="dropdown-list-cat">
                        {this.state.catAllData.map((item: any) => (
                          <li
                            key={item}
                            id="dropdowndata"
                            className="dropdown-item"
                            data-test-id="dropdowndata"
                            onMouseOver={() => this.handleCategoryClick(item)}
                          >
                            <img style={{ marginRight: "7px", mixBlendMode: "multiply" }} src={require("./right.jpg")} alt={item.label} className="img" />
                            <Box component={"a"}>{item.name}</Box>
                          </li>
                        ))}
                      </ul>
                    )}
                  </Hidden>
                  {this.state.isSubMenuOpen && (
                    <ul className="sub-menu">
                      <div style={{ display: "flex" }}>
                        {this.state.subCat?.slice(0, 5).map((data: any, index:number) => {
                          return (
                            <>
                            <Box key={index}>
                              <Box style={{ display: "flex", flexDirection: "column", marginTop: "15px", marginBottom: "20px" }}>
                                <li>
                                  <div className="menu-item-label-sub" style={{ fontSize: "13px", fontWeight: 600 }}>{data.name}</div>
                                  {
                                    data.products.slice(0, 5).map((product:any)=>{
                                      return(
                                        <div className="menu-item-label-sub-data" >{product.name}</div>
                                      )
                                    })
                                  }

                                </li>
                              </Box>
                            </Box>
                              <div style={{ background: "#CBD5E1", height: "200px", width: "2px", margin: "auto" }}></div>
                              </>
                          )
                        })}
                      </div>
                      <Box style={webStyle.viewBox}>
                      <Button style={{textTransform:"none", ...webStyle.viewButton}}>View All</Button>
                      </Box>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          </NavigationBlock>
          {this.state.platform !=='mobile' && 
          <SubNavigationBlock>
            <Box className='main-block'>
              <Box className='category-content' onClick={this.handleDrodownCat}>
                <Box sx={{ fontSize: "12px", fontWeight: 700, fontFamily: 'Inter, sans-serif', whiteSpace: "nowrap", display: "flex", color:"#5F5F5F", alignItems:"center" }}>
                  <Typography className='category-content-text'>Top Categories</Typography>
                  <ExpandMore className='down-icon' />
                </Box>
              </Box>

              <Box className="horizontal-scroll-menu">
                {this.state.catData?.length > 0 ? (
                  <Box className="scroll-buttons">
                    <ChevronLeft className='right-left-icon' onClick={() => this.handleScroll('left')} />
                  </Box>
                ) : ""}
                <Box id="scroll-container" className="scroll-container">
                  {this.state.catData?.map((data: any) => {
                    return (
                      <div 
                        {...(this.state.platform === 'mobile'
                          ? { onClick: () => this.onMouseEnter(data) }
                          : { onMouseEnter: () => this.onMouseEnter(data) }
                        )}
                      >
                      <Typography key={data.name} 
                        {...(this.state.platform === 'mobile'
                        ? {}
                        : { onClick:() => this.handleClick(data.name)}
                        )}                      
                      className={this.state.selCatId1==data.id?"menu-item-scr-active":"menu-item-scr"}>{data.name}</Typography>
                      </div>
                    )
                  })}
                </Box>
                {this.state.catData?.length > 0 ? (
                  <div className="scroll-buttons">
                    <ChevronRight className='right-left-icon' onClick={() => this.handleScroll('right')} />
                  </div>
                ) : ""}
              </Box>
              <Hidden>
                
                {this.state.showMegaMenu && (
                  <div className='mobileShadow'>
                  <Grid container className="dropdown-megamenu" onMouseLeave={()=>{this.onMouseLeave()}}>
                     {this.state.subCat2?.map((elem:any, id:number)=>{
                      return(
                        <Grid item key={id} xs={10} sm={10} md={2} lg={2} className='megamenu-cat'>
                          <Typography className='megamenu-main-cat' onClick={() => this.handleClick(elem.attributes.name)}>
                            {elem.attributes.name}
                          </Typography>
                          {this.state.subCat3 && this.state.subCat3.filter((elem3:any)=>elem3?.attributes?.parent_category_id == elem.attributes.id).map((e:any)=>{
                            return (
                              <Typography className='megamenu-sub-cat' onClick={() => this.handleClick(e.attributes.name)}>
                                {e.attributes.name}
                              </Typography>
                            )
                          })}
                        </Grid>
                      )
                     })}   
                  </Grid>
                  </div>
                )}
                    </Hidden>
              <Box sx={webStyle.bulkOrderDiv}>
                <Box className='subNavBtn'>
                  <Typography className='bulkOrder'>
                    Customized Products
                  </Typography>
                  <ExpandMore className='down-icon' />
                </Box>
                <Box className='subNavBtn'>
                  <Typography className='bulkOrder'>
                    Hire Pro
                  </Typography>
                  <ExpandMore className='down-icon' />
                </Box>
                <Box className='subNavBtn'>
                  <Box sx={webStyle.bulkOrder}>Audit Report</Box>
                </Box>
              </Box>
            </Box>
          </SubNavigationBlock>}
        </>
        ) : (
          < NavigationBlockWOLogin>
            <nav className="navMenu">
            <Grid container justifyContent="space-between" direction="row" alignItems="center">
              <Grid item direction="row" className="navTextAndLogo">
                <Grid container direction="row" alignItems="center" >
                  <Grid item className="logo" onClick={()=>{this.handlePagesClick("logo")}}>
                  <svg width="214" height="90" viewBox="0 0 214 90" fill="none" xmlns="http://www.w3.org/2000/svg" className='logo'>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M62.3359 89.9789H86.6248L63.1464 48.1662L46.3133 62.5845L62.3359 89.9789ZM82.5675 19.4618L105.105 0.0195312H81.9726L32.4795 42.0431L42.1331 0.0195312H21.4415L0.777344 89.9789H21.4688L26.6934 67.2322L32.1911 62.6073L82.5675 19.4618Z" fill="#1B1918"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M194.745 89.9789L173.919 47.591L159.447 61.6677L158.176 63.061L170.714 89.9789H194.745ZM175.817 39.3307L213.224 0.0195312H190.734L167.691 24.9125L168.326 26.2377L175.817 39.3307Z" fill="#1B1918"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M141.1 43.4578L132.939 29.1929L118.949 0.0195312H95.3036L116.763 44.2289L72.9688 89.9789H96.8722L125.379 58.7503L141.1 43.4578Z" fill="#FF6600"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M171.889 43.4578L163.728 29.1929L149.738 0.0195312H126.093L147.552 44.2289L103.758 89.9789H127.661L156.168 58.7503L171.889 43.4578Z" fill="#FF6600"/>
                  </svg>
                  </Grid>
                  <Grid item>
                    <Grid container className="navText" alignItems="center" spacing={2}>
                      <Grid item sm={1} md={4} lg={4} className={this.checkActivePage("/Platform", "navText__text")} onClick={()=>{this.handlePagesClick("platform")}}>
                        Platform
                      </Grid> 
                      <Grid item sm={2} md={5} lg={5} className={this.checkActivePage("/Price", "navText__text")} onClick={()=>{this.handlePagesClick("materials")}}>
                        Material Prices 
                      </Grid>
                      <Grid item sm={1} md={3} lg={3} className={this.checkActivePage("/News", "navText__text")} onClick={()=>{this.handlePagesClick("news")}}>
                        News
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <button className="loginBtn" onClick={this.handleloginClick}>
                  Login
                  <ArrowDown />
                </button>
              </Grid>
            </Grid>
            </nav>
          </NavigationBlockWOLogin>
        )}
      </>
    )
  }
}
const webStyle = {
  viewButton:{color:"#FF6600",   backgroundColor: "transparent", 
        transition: "background-color 0.3s", 
        "&:hover": {
            backgroundColor: "white", 
        },},
  viewBox: { display:"flex" , justifyContent:'end', marginRight:"40px"},
  bulkOrderDiv: {
    display: "flex",
    gap: "20px",
    marginRight: "10px",
    '@media (max-width: 600px)': {
      gap: "10px !important",
      width:"100vw"
    }
  },
  loginText: {
    marginLeft: "10px",
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: 'Inter, sans-serif',
    cursor: "pointer"
  },
  profileIcon: {
    color: "#FFFFFF",
    fontSize: "30px",
  },
  profileIconContainer: {
    height: "44px",
    width: '44px',
    borderRadius: "50%",
    bgcolor: "#FF6600",
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    '@media (max-width: 600px)': {
      width: "30px",
      height: "30px",
      padding: "3px"
    }
  },
  bulkOrder: {
    fontWeight: 700, fontFamily: 'Inter, sans-serif', fontSize:"12px", color:"#1E293B",
    whiteSpace:"nowrap",
    cursor:"pointer",
    "@media (max-width:900px)": {
      fontSize:"20px",
      whiteSpace:"wrap",
    },
  },
  navbar: {
    display: 'flex',
    justifyContent: { xs: "space-between", },
    alignItems: "center",
    flexDirection: "row",
    padding: "5px",
    height: "76px",
    '@media (max-width: 900px)': {
      flexWrap: 'wrap',
      padding: '5px 0px',
      height: 'auto',
      width: '100vw',
    }
  },
  languageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  displayBox: {
    display: { xs: "none", md: 'block', lg: "block" }
  },
  displayUploadBox: {
    display: { xs: "none",sm:"block", md: 'block', lg: "block" }
  },
  searchContent: {
    margin: "0 10px",
    display: "flex",
    flexDirection: "row",
    padding: "12px 20px",
    fontSize:'12px',
    height: "30px",
    justifyContent: "space-between",
    borderRadius: "9px",
    alignItems: "center",
    backgroundColor:"#F3F4F6",
    marginLeft:"34px",
    '@media (max-width: 600px)': {
      '& .MuiTypography-body1': {
        fontSize: "10px !important"
      },
      padding: "1px 0px",
      marginLeft:"10px",
    },
    "& .basic-multi-select": {
      minWidth: "550px",
      fontSize:'12px',
      fontWeight:400,
      fontFamily: 'Inter, sans-serif',
    },
    "& [class*=' css-yk16xz-control']":{
      borderColor:"#F3F4F6",
    },
    "& .MuiBox-root-6": {
      fontSize: "12px"
    },
    "& .css-1rhbuit-multiValue": {
      backgroundColor: "#fff !important",
      border: "1px solid orange",
      color: "orange !important"
    },
    "& .css-12jo7m5": {
      color: "orange",
      fontSize: "14px"
    },
    "& .css-xb97g8:hover": {
      backgroundColor: "transparent !important",
      color: "orange !important"
    },
    "& .css-1okebmr-indicatorSeparator": {
      display: "none"
    },
    "& .css-1xc3v61-indicatorContainer .css-tj5bde-Svgg": {
      display: "none !important"
    },
    "& .css-tlfecz-indicatorContainer .css-6q0nyr-Svg": {
      display: "none !important"
    },
    "& .css-6q0nyr-Svg": {
      display: "none !important"
    },
    '& [class=" css-tlfecz-indicatorContainer"]':{
      display: "none !important"
    },
    "& .css-lgtu0rj-indicatorContainer .css-6q0nyr-Svg": {
      display: "none !important"
    },
    "& .css-1n7v3ny-option": {
      backgroundColor: "rgb(236, 239, 242)"
    },
    "& .menu-options": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    "& .css-yk16xz-control": {
      backgroundColor: "#F6F6F6"
    },
    '@media (max-width: 1166px)': {
      "& .basic-multi-select": {
        minWidth: "200px !important"
      },
    },
    '@media (max-width: 1082px)': {
      "& .basic-multi-select": {
        minWidth: "100px !important",
      },
    },
    '@media (max-width: 547px)': {
      "& .basic-multi-select": {
        minWidth: "20px !important",
      }
    },
    '@media (max-width: 940px)': {
      "& .css-12jo7m5": {
        fontSize: "10px !important"
      },
      "& .basic-multi-select": {
        minWidth: "100px !important"
      }
    },
    '@media (max-width: 1385px)': {
      "& .basic-multi-select": {
        minWidth: "350px",
      }
    },
  },
  product: {
    '@media (max-width: 600px)': {
      display: "none",
    }
  },
  navItems: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: "30%",
    padding: '0 5px'
  }
};
export default Navbar